import { useFieldArray, useFormContext } from "react-hook-form";
import { FormArray } from "../../../../../components/UI/Form/FormArray";
import GenericService from "../../../../../services/generics.service";
import { TEstabilishmentForm } from "../EstabilishmentSchema";
import { useCallback, useEffect, useState } from "react";
import { ISelectType } from "../../../../../models/requests";
import { useFnRequest } from "../../../../../hooks/useFnRequest/useFnRequest";
import EstablishmentMailMethod from "./EstablishmentMailMethod";
import { Grid } from "@mui/material";
import { FormInput } from "../../../../../components/UI/Form/FormTextInput";

const MAIL_METHOD_ROUTE = process.env.REACT_APP_API + "/api/generic";
const mailMethodService = new GenericService(MAIL_METHOD_ROUTE);

const EstablishmentMails = () => {
    const { control, formState } = useFormContext<TEstabilishmentForm>();

    const [mailMethodTypes, setMailMethodTypes] = useState<ISelectType[]>([]);

    const [defaultMailMethod, setDefaultMailMethod] = useState<ISelectType>();

    const methods = useFieldArray<
        TEstabilishmentForm,
        "mailMethods",
        "key"
    >({
        control,
        name: "mailMethods",
        keyName: "key",
    });

    const { sendRequest } = useFnRequest(mailMethodService.list);

    const fetchLanguages = useCallback(async () => {
        const { data, success } = await sendRequest("MAIL_METHOD");
        if (data && success) {
            setMailMethodTypes(data);
        }
    }, [sendRequest]);

    useEffect(() => {
        fetchLanguages();
    }, [fetchLanguages]);

    const loaddefaultMailMethod = useCallback((method: ISelectType) => {
        setDefaultMailMethod(method);
    }, []);

    return (
        <FormArray
            title="Configurações de email"
            name="mailMethods"
            errorMessage={formState.errors?.mailMethods?.message}
            addButtonLabel={"Adicionar configuração de email"}
            showAddButton={!methods.fields.length}
            formArray={methods}
            showDeleteButton={true}
            rowAlign="start"
            appendValue={{
                publicKey: "",
                privateKey: "",
                method: mailMethodTypes[0]
            }}
            fieldsObject={(index) => {
                const publicKey = `mailMethods.${index}.publicKey`;
                const privateKey = `mailMethods.${index}.privateKey`;
                return (
                    <>
                        <EstablishmentMailMethod
                            index={index}
                            data={mailMethodTypes || []}
                            loadDefaultMethod={loaddefaultMailMethod}
                        />

                        <Grid item xs={12} lg={defaultMailMethod && defaultMailMethod.code === "2" ? 4 : 8} mb={1}>
                            <FormInput name={publicKey} label="Public Key" size="small" fullWidth />
                        </Grid>

                        {defaultMailMethod && defaultMailMethod.code === "2" && (
                            <Grid item xs={12} lg={4} mb={1}>
                                <FormInput name={privateKey} label="Private Key" size="small" fullWidth />
                            </Grid>
                        )}
                    </>
                );
            }}
        />
    );
};

export default EstablishmentMails;
