import React, { useEffect } from "react";
import { IconButton, Stack, TextField, Tooltip } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { ChangeEvent, memo, useState } from "react";

import { ISearchInput } from "./models";
import { Form } from "react-router-dom";

export const SearchInput: React.FC<ISearchInput> = memo(
  ({
    onSearch,
    label,
    focus = false,
    minLength,
    type,
    currentValue,
    minWidth,
    maxWidth,
  }) => {
    const [value, setValue] = useState<string>("");
    const [error, setError] = useState(false);

    const onChangeHandler = (
      event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
      setValue(event.target.value);
    };

    const onClickHandler = () => {
      if (value.length !== 0 && value.length < minLength) {
        setError(true);
      } else {
        setError(false);
      }
      onSearch(value);
    };

    useEffect(() => {
      if (!currentValue || currentValue?.length === 0) {
        setValue("");
      } else {
        setValue(currentValue);
      }
    }, [currentValue]);

    return (
      <Form onSubmit={onClickHandler}>
        <TextField
          fullWidth
          onChange={onChangeHandler}
          label={label}
          value={value}
          size="small"
          autoFocus={focus}
          sx={{ minWidth: minWidth, maxWidth: maxWidth }}
          type={type}
          onBlur={(e) => {
            if (value.length !== 0 && value.length < 3) {
              setError(true);
            } else {
              setError(false);
            }
            if (e.target.value.length === 0) {
              onSearch("");
              setValue("");
            }
          }}
          InputProps={{
            endAdornment: (
              <Tooltip title="Clique para pesquisar">
                <Stack>
                  <IconButton onClick={() => onSearch(value)} type="submit">
                    <SearchIcon />
                  </IconButton>
                </Stack>
              </Tooltip>
            ),
          }}
          variant="outlined"
          error={error}
          helperText={
            error ? `Informe pelo menos ${minLength} caracter(es)` : ""
          }
        />
      </Form>
    );
  }
);
