import { z } from "zod";

const privilegesSchema = z.object({
  id: z.string(),
  name: z.string(),
  descripion: z.string().optional(),
  type: z.string().optional(),
  enable: z.boolean().optional(),
  domainId: z.string().optional(),
  actions: z.string().optional(),
});

export const userProfileSchema = z.object({
  id: z.string().optional(),
  enable: z.boolean(),
  name: z.string().min(1, "O nome é obrigatório"),
  description: z.string().min(1, "A descrição é obrigatória"),
  privileges: z
    .array(privilegesSchema)
    .nonempty("Ao menos um privilégio é obrigatório"),
});

export type TUserProfileForm = z.infer<typeof userProfileSchema>;
