import {
  Box,
  Button,
  CircularProgress,
  Divider,
  FormHelperText,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";

import { FieldArrayWithId } from "react-hook-form";
import { useLocation, useParams } from "react-router-dom";
import { useCallback, useState } from "react";

import { IFormArray } from "./models";
import { genericMemo } from "../../../../utils/genericMemo";

export const FormArray = genericMemo(
  <T extends object>({
    formArray,
    appendValue,
    title,
    addButtonLabel,
    fieldsObject,
    errorMessage,
    onDelete,
    onDeleteVoid,
    showDeleteButton = true,
    rowAlign = "center",
    deleteBtnMg,
    showAddButton = true,
  }: IFormArray<T>) => {
    const { fields, remove, append } = formArray;
    const { id } = useParams();
    const [deleteList, setDeleteList] = useState<string[]>([]);
    const { pathname } = useLocation();
    const viewMode = pathname.includes("visualizar");

    const addToDeletelist = (id: string) => {
      setDeleteList((oldState) => [...oldState, id]);
    };

    const removeFromDeletelist = (id: string) => {
      setDeleteList((oldState) => oldState.filter((sid) => sid !== id));
    };

    const isInDeleteList = (id: string) => {
      return !!deleteList.find((d) => d === id);
    };

    const handleDelete = useCallback(
      async (index: number, field: FieldArrayWithId<T, any, any>) => {
        if (onDelete && field.id && field.id.trim().length > 1 && id) {
          addToDeletelist(id);
          const response = await onDelete(id, field.id);
          if (response) {
            removeFromDeletelist(id);
            remove(index);
          }
        } else {
          remove(index);
        }
      },
      [onDelete, remove, id]
    );

    const handleDeleteVoid = useCallback(
      (index: number, field: FieldArrayWithId<T, any, any>) => {
        if (onDeleteVoid && id) {
          onDeleteVoid(field);
          remove(index);
        }
      },
      [onDeleteVoid, remove, id]
    );

    return (
      <Stack width={"100%"} pl={2} alignItems={"start"} spacing={1}>
        <Typography variant="h6">{title}</Typography>
        <FormHelperText error>{errorMessage}</FormHelperText>
        <Stack spacing={3} width={"100%"}>
          {fields.map((field, index) => {
            return (
              <Box key={field.id || field.key}>
                <Stack
                  direction={"row"}
                  alignItems={rowAlign}
                  mb={index !== fields.length - 1 ? 1 : 0}
                >
                  <Grid container spacing={2} width={"100%"}>
                    {fieldsObject(index)}
                  </Grid>
                  {!isInDeleteList(field.id) &&
                    !viewMode &&
                    showDeleteButton && (
                      <IconButton
                        aria-label="delete"
                        size="small"
                        sx={{
                          mb: deleteBtnMg,
                          mt: rowAlign === "start" ? 1 : 0,
                        }}
                        onClick={() => {
                          handleDeleteVoid(index, field);
                          if (!onDeleteVoid) {
                            handleDelete(index, field);
                          }
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    )}
                  {isInDeleteList(field.id) && (
                    <CircularProgress
                      size={30}
                      sx={(theme) => ({ marginLeft: theme.spacing(1) })}
                    />
                  )}
                </Stack>
                {index !== fields.length - 1 && (
                  <Divider variant="middle" light />
                )}
              </Box>
            );
          })}
        </Stack>
        {!viewMode && showAddButton && (
          <Button
            onClick={() => append(appendValue)}
            startIcon={<AddIcon />}
            sx={(theme) => ({
              marginLeft: theme.spacing(4),
            })}
          >
            {addButtonLabel || "Add"}
          </Button>
        )}
      </Stack>
    );
  }
);
