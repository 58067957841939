import { Grid } from "@mui/material";
import { FormContainer } from "../../../../components/UI/Form/FormContainer";
import { FormInput } from "../../../../components/UI/Form/FormTextInput";
import { SwitchInput } from "../../../../components/UI/Form/Switch/Switch";
import { useFnRequest } from "../../../../hooks/useFnRequest/useFnRequest";
import { FormTextEditor } from "../../../../components/UI/Form/FormTextEditor";
import {
  createContentHelp,
  getContentHelpById,
  updateContentHelpById,
} from "../../../../services/contentHelp.service";
import { THelpContentForm, helpContentSchema } from "./HelpContentSchema";

const HelpContent = () => {
  const { sendRequest: save, loading: sLoading } =
    useFnRequest(createContentHelp);

  const { sendRequest: update, loading: uLoading } = useFnRequest(
    updateContentHelpById
  );

  const { sendRequest: get, loading: gLoading } =
    useFnRequest(getContentHelpById);

  const saveHandler = (v: THelpContentForm) => {
    return save({
      ...v,
    });
  };

  const updateHandler = (v: THelpContentForm, id: string) => {
    return update({
      item: {
        ...v,
      },
      id,
    });
  };

  const loading = sLoading || uLoading || gLoading;

  return (
    <FormContainer<THelpContentForm>
      title={"Conteúdo de Ajuda"}
      saveFunction={saveHandler}
      updateFunction={updateHandler}
      getFunction={get}
      loading={loading}
      schema={helpContentSchema}
      subtitle={"Novo conteúdo de ajuda"}
      subtitleWatchField={"title"}
      fields={() => (
        <Grid container spacing={2}>
          <Grid item sm={12} textAlign={"end"}>
            <SwitchInput label="Ativar" name="enable" />
          </Grid>

          <Grid item sm={12}>
            <FormInput
              name="title"
              label="Título"
              autoFocus
              size="small"
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <FormTextEditor
              name="content"
              menuBarProps={{
                fontSize: true,
                markFormatting: true,
                textFormatting: true,
                selectColor: false,
              }}
              maxHeight="400px"
            />
          </Grid>
        </Grid>
      )}
    />
  );
};

export default HelpContent;
