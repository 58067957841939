import "./Editor.css";

import { Editor } from "@tiptap/react";
import React from "react";

import {
  Grid,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { MuiColorInput } from "mui-color-input";

import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import StrikethroughSIcon from "@mui/icons-material/StrikethroughS";
import CodeIcon from "@mui/icons-material/Code";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import TerminalIcon from "@mui/icons-material/Terminal";
import FormatClearIcon from "@mui/icons-material/FormatClear";
import WrapTextIcon from "@mui/icons-material/WrapText";
import UndoIcon from "@mui/icons-material/Undo";
import RedoIcon from "@mui/icons-material/Redo";
import { IMenuBarProps } from "./models";

const MenuBar: React.FC<{
  editor: Editor | null;
  menuBarProps?: IMenuBarProps;
}> = ({
  editor,
  menuBarProps = {
    fontSize: true,
    markFormatting: true,
    selectColor: true,
    textFormatting: true,
  },
}) => {
  if (!editor) {
    return null;
  }
  return (
    <Grid container alignItems={"center"} gap={2}>
      {menuBarProps.textFormatting && (
        <Grid item>
          <ToggleButtonGroup aria-label="text formatting" size="small">
            <ToggleButton
              value={"bold"}
              selected={editor.isActive("bold")}
              aria-label="bold"
              onClick={() => editor.chain().focus().toggleBold().run()}
              disabled={!editor.can().chain().focus().toggleBold().run()}
            >
              <FormatBoldIcon />
            </ToggleButton>

            <ToggleButton
              value={"italic"}
              selected={editor.isActive("italic")}
              onClick={() => editor.chain().focus().toggleItalic().run()}
              disabled={!editor.can().chain().focus().toggleItalic().run()}
            >
              <FormatItalicIcon />
            </ToggleButton>

            <ToggleButton
              value={"strike"}
              selected={editor.isActive("strike")}
              onClick={() => editor.chain().focus().toggleStrike().run()}
              disabled={!editor.can().chain().focus().toggleStrike().run()}
            >
              <StrikethroughSIcon />
            </ToggleButton>

            <ToggleButton
              value={"code"}
              selected={editor.isActive("code")}
              onClick={() => editor.chain().focus().toggleCode().run()}
              disabled={!editor.can().chain().focus().toggleCode().run()}
            >
              <CodeIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      )}
      {menuBarProps.fontSize && (
        <Grid item>
          <TextField
            type="number"
            size="small"
            value={editor.getAttributes("textStyle")?.fontSize || 12}
            InputProps={{
              inputProps: {
                max: 100,
                min: 10,
              },
            }}
            onChange={(e) =>
              editor.chain().focus().setFontSize(e.target.value).run()
            }
          />
        </Grid>
      )}
      {menuBarProps.markFormatting && (
        <Grid item>
          <ToggleButtonGroup aria-label="mark formatting" size="small">
            <ToggleButton
              value={"heading1"}
              selected={editor.isActive("heading", { level: 1 })}
              onClick={() =>
                editor.chain().focus().toggleHeading({ level: 1 }).run()
              }
            >
              <Typography fontWeight={"bold"}>H1</Typography>
            </ToggleButton>

            <ToggleButton
              value={"heading2"}
              selected={editor.isActive("heading", { level: 2 })}
              onClick={() =>
                editor.chain().focus().toggleHeading({ level: 2 }).run()
              }
            >
              <Typography fontWeight={"bold"}>H2</Typography>
            </ToggleButton>

            <ToggleButton
              value={"paragraph"}
              selected={editor.isActive("paragraph")}
              onClick={() => editor.chain().focus().setParagraph().run()}
            >
              <Typography fontWeight={"bold"}>P</Typography>
            </ToggleButton>

            <ToggleButton
              value={"bulletList"}
              selected={editor.isActive("bulletList")}
              onClick={() => editor.chain().focus().toggleBulletList().run()}
            >
              <FormatListBulletedIcon />
            </ToggleButton>

            <ToggleButton
              value={"orderedList"}
              selected={editor.isActive("orderedList")}
              onClick={() => editor.chain().focus().toggleOrderedList().run()}
            >
              <FormatListNumberedIcon />
            </ToggleButton>

            <ToggleButton
              value={"codeBlock"}
              selected={editor.isActive("codeBlock")}
              onClick={() => editor.chain().focus().toggleCodeBlock().run()}
            >
              <TerminalIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      )}

      {menuBarProps.markFormatting && (
        <Grid item>
          <ToggleButtonGroup aria-label="mark formatting" size="small">
            <ToggleButton
              value={"blockquote"}
              selected={editor.isActive("blockquote")}
              onClick={() => editor.chain().focus().toggleBlockquote().run()}
            >
              <FormatQuoteIcon />
            </ToggleButton>
            <ToggleButton
              value={"horizontalRule"}
              onClick={() => editor.chain().focus().setHorizontalRule().run()}
            >
              <HorizontalRuleIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      )}

      {menuBarProps.markFormatting && (
        <Grid item>
          <ToggleButtonGroup aria-label="mark formatting" size="small">
            <ToggleButton
              value={"clearNodes"}
              onClick={() => editor.chain().focus().clearNodes().run()}
            >
              <FormatClearIcon />
            </ToggleButton>
            <ToggleButton
              value={"setHardBreak"}
              onClick={() => editor.chain().focus().setHardBreak().run()}
            >
              <WrapTextIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      )}

      {menuBarProps.markFormatting && (
        <Grid item>
          <ToggleButtonGroup aria-label="mark formatting" size="small">
            <ToggleButton
              value={"undo"}
              onClick={() => editor.chain().focus().undo().run()}
              disabled={!editor.can().chain().focus().undo().run()}
            >
              <UndoIcon />
            </ToggleButton>
            <ToggleButton
              value={"redo"}
              onClick={() => editor.chain().focus().redo().run()}
              disabled={!editor.can().chain().focus().redo().run()}
            >
              <RedoIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      )}

      {menuBarProps.selectColor && (
        <MuiColorInput
          size="small"
          onChange={(color) => editor.chain().focus().setColor(color).run()}
          value={editor.getAttributes("textStyle")?.color || "rgb(0, 0, 0)"}
        />
      )}
    </Grid>
  );
};

export default MenuBar;
