import { GridColDef } from "@mui/x-data-grid";
import { Table } from "../../../components/UI/Table/Table";
import { CNPJ_FORMAT } from "../../../utils/regex";
import { IGetEstablishment } from "../../../models/establishments";
import { MainApiService } from "../../../services/mainApi";
import { Chip, Tooltip, Typography } from "@mui/material";

const ESTABLISHMENT_ROUTE = process.env.REACT_APP_API + "/api/establishment";
const establishmentService = new MainApiService<IGetEstablishment>(
  ESTABLISHMENT_ROUTE
);

const EstablishmentsPage = () => {
  const columns: GridColDef<IGetEstablishment>[] = [
    {
      field: "name",
      headerName: "Nome",
      flex: 1,
      minWidth: 300,
      renderCell: (params) => {
        return (
          <Tooltip title={params.value + ` (${params.row.code})`}>
            <Typography
              overflow={"hidden"}
              whiteSpace={"nowrap"}
              textOverflow={"ellipsis"}
              variant="subtitle2"
            >
              {params.value + ` (${params.row.code})`}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      field: "siteName",
      headerName: "Nome de exibição no site",
      flex: 1,
    },
    {
      field: "document",
      headerName: "CNPJ",
      flex: 1,
      renderCell: (params) => {
        return params.value.replace(CNPJ_FORMAT, "$1.$2.$3/$4-$5");
      },
    },
    {
      field: "enable",
      headerName: "Ativo",
      flex: 1,
      renderCell: (params) => {
        return (
          <Chip
            color={params.value ? "success" : "error"}
            size="small"
            label={params.value ? "Ativo" : "Inativo"}
          />
        );
      },
    },
  ];

  return (
    <Table<IGetEstablishment>
      service={establishmentService}
      showDefaultMacroFilters={false}
      showButton={false}
      columns={columns}
      title={"Estabelecimentos"}
      defaultActions={false}
      searchInput
      searchInputPlaceHolder={"Nome"}
    />
  );
};

export default EstablishmentsPage;
