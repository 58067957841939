/**
 * A function to get string initials
 * 
 * @param str A string
 * @returns The string words initials
 * 
 * @example
 * 
 * getInitials("Micael Luan Conti") return "MLC"
 */
export const getInitials = (str: string) => {
  const stringArr = str.split(" ");
  let initials = "";

  stringArr.forEach((v, i) => {
    if (i <= 1) {
      initials += v[0];
    }
  });

  return initials;
};
