import { useCallback, useEffect, useState } from "react";
import {
  ALL_APP_DOMAIN,
  Permission,
  useDomain,
} from "../../contexts/Domain-Context";

/**
 * An hook to determinate permission
 *
 * @param permissions an array of permissions
 * @returns a boolean indicating if permission is true or false
 */
export const useRole = (permissions: Permission[], privilegeName?: string) => {
  const DomainContext = useDomain();

  if (DomainContext === null) {
    throw new Error("No DomainContext provided");
  }

  const { privileges, domain } = DomainContext;

  const [hasPermission, setHasPermission] = useState(false);

  const checkPrivileges = useCallback(() => {
    const allPrivileges = privileges.flatMap((priv) => priv.privileges);
    if (allPrivileges.find((d) => d === ALL_APP_DOMAIN)) {
      setHasPermission(true);
      return;
    }
    const actualPrivilege = domain + "-" + permissions.toString().toLowerCase();
    const privilegeAll = domain + "-all";

    if (allPrivileges.includes(privilegeAll)) {
      setHasPermission(true);
      return;
    }

    if (privilegeName) {
      if (allPrivileges.includes(privilegeName)) {
        setHasPermission(true);
        return;
      } else {
        setHasPermission(false);
      }
    }

    if (allPrivileges.includes(actualPrivilege)) {
      setHasPermission(true);
      return;
    } else {
      setHasPermission(false);
    }
  }, [privileges, privilegeName, domain, permissions]);

  useEffect(() => {
    checkPrivileges();
  }, [checkPrivileges]);

  return hasPermission;
};
