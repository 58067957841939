import {
  IconButton,
  Stack,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { motion } from "framer-motion";
import { ITableActions } from "./models";
import { Button } from "../Button";

export const TableActions = <T extends object>({
  onAddHandler,
  addButtonLabel,
  actionMenuItems,
  showAddButton = true,
  showMoreOptions = false,
}: ITableActions<T>) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <motion.div
      initial={{ y: "-110%" }}
      animate={{ y: 0 }}
      exit={{ y: "-110%" }}
      transition={{ type: "spring" }}
    >
      <Stack direction={"row"} spacing={1}>
        <Stack flexDirection={"row"} gap={matches ? 0 : 1}>
          {showMoreOptions &&
            actionMenuItems &&
            actionMenuItems.map((item) => {
              if (matches) {
                return (
                  <Tooltip title={item.text} key={item.text}>
                    <IconButton onClick={item.action}>{item.icon}</IconButton>
                  </Tooltip>
                );
              } else {
                return (
                  <Button
                    variant="outlined"
                    startIcon={!item.loading && item.icon}
                    onClick={item.action}
                    key={item.text}
                    loading={item.loading}
                  >
                    {item.text}
                  </Button>
                );
              }
            })}
        </Stack>
        {showAddButton && (
          <Button
            variant="contained"
            startIcon={<AddRoundedIcon />}
            onClick={onAddHandler}
          >
            {addButtonLabel || "Add"}
          </Button>
        )}
      </Stack>
    </motion.div>
  );
};
