import React from "react";
import { useCallback, useState } from "react";
import {
  Drawer,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  Stack,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import TuneIcon from "@mui/icons-material/Tune";
import CloseIcon from "@mui/icons-material/Close";

import { IColumnsDrawer } from "./model";

const ColumnsDrawer = ({
  columnVisibilityModel,
  VisibilityChanges,
  columns,
}: IColumnsDrawer) => {
  const [open, setOpen] = useState(false);

  const openDrawer = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const closeDrawer = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleChange = (
    field: string,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    VisibilityChanges({
      ...columnVisibilityModel,
      [field]: event.target.checked,
    });
  };

  const getColumnName = (field: string) => {
    const columnFind = columns.find((c) => c.field === field);
    if (columnFind) {
      return columnFind.headerName || columnFind.field;
    }
    return field;
  };

  return (
    <>
      <Tooltip title={"Configurar colunas visíveis"}>
        <IconButton aria-label="delete" color="primary" onClick={openDrawer}>
          <TuneIcon />
        </IconButton>
      </Tooltip>

      <Drawer anchor={"right"} open={open} onClose={closeDrawer}>
        <Stack padding={3} width={350}>
          <Stack alignItems={"flex-end"}>
            <IconButton aria-label="delete" onClick={closeDrawer}>
              <CloseIcon />
            </IconButton>
          </Stack>
          <Typography variant="h5" mb={2}>
            Colunas visíveis
          </Typography>
          <Typography variant="body1" mb={1}>
            Selecione abaixo quais colunas deseja que estejam visíveis
          </Typography>

          <FormControl component="fieldset" variant="standard">
            <FormGroup>
              {Object.keys(columnVisibilityModel).map((key) => {
                return (
                  <FormControlLabel
                    key={key}
                    control={
                      <Switch
                        checked={columnVisibilityModel[key]}
                        onChange={(event) => handleChange(key, event)}
                        name={key}
                      />
                    }
                    label={getColumnName(key)}
                  />
                );
              })}
            </FormGroup>
          </FormControl>
        </Stack>
      </Drawer>
    </>
  );
};

export default ColumnsDrawer;
