import { Table } from "../../../components/UI/Table";
import { GridColDef } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { IGetUserProfiles } from "../../../models/user-profiles";
import MainApiService from "../../../services/mainApi/mainApi.service";

const USER_PROFILES_ROUTE = process.env.REACT_APP_API + "/api/profile";
const userProfilesService = new MainApiService<IGetUserProfiles>(
  USER_PROFILES_ROUTE
);

const UserProfilesPage = () => {
  const navigate = useNavigate();

  const columns: GridColDef<IGetUserProfiles>[] = [
    {
      field: "name",
      headerName: "Nome",
      flex: 1,
    },
    {
      field: "description",
      headerName: "Descrição",
      flex: 1,
    },
  ];

  const addHanlder = () => navigate("novo");

  return (
    <Table<IGetUserProfiles>
      service={userProfilesService}
      showDefaultMacroFilters={false}
      columns={columns}
      title={"Perfis de usuário"}
      searchInput
      onAdd={addHanlder}
      searchInputPlaceHolder={"Pesquisar"}
      addButtonLabel={"Adicionar perfil de usuário"}
      confirmOptions={{
        title: "Deletar perfil de usuário?",
        description: "Isso vai permanentemente deletar o perfil de usuário",
        confirmationButtonProps: {
          variant: "contained",
        },
      }}
    />
  );
};

export default UserProfilesPage;
