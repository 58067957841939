import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { FormHelperText, Grid } from "@mui/material";
import { AxiosResponse } from "axios";
import { TransferList } from "../../TransferList";
import { ISelectType } from "../../../../models/requests";

export const FormTransferList: React.FC<{
  name: string;
  software?: string;
  request: (params?: any) => Promise<AxiosResponse<ISelectType[], any>>;
}> = ({ name, software, request }) => {
  const { control } = useFormContext();

  return (
    <Controller
      render={({ fieldState: { error }, field }) => {
        const { onChange, value } = field;
        return (
          <Grid>
            <TransferList
              listRequest={request}
              name={name}
              software={software}
              value={value}
              onChange={onChange}
            />
            <FormHelperText error>{error ? error?.message : ""}</FormHelperText>
          </Grid>
        );
      }}
      defaultValue={[]}
      name={name}
      control={control}
    />
  );
};
