import { Badge, IconButton, Stack, Tooltip } from "@mui/material";
import { IFilter, ITextFilterProps } from "../models";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { Modal } from "../../Modal";
import { useCallback, useEffect, useState } from "react";
import { Button } from "../../Button";
import FiltersModal from "./FIltersModal";

const Filters = ({
  textFilters,
  applyFilters,
  cleanFilters,
  serviceProps,
  filters,
}: {
  filters: IFilter[];
  textFilters?: ITextFilterProps[];
  applyFilters?: () => void;
  cleanFilters?: () => void;
  serviceProps: Record<string, any> | undefined;
}) => {
  const [open, setOpen] = useState(false);
  const [filtersLength, setFiltersLength] = useState(0);

  const closeModalHandler = () => {
    const hasFilterStorage = !!localStorage.getItem(window.location.pathname);

    if (filtersLength !== 0 && !hasFilterStorage) {
      localStorage.setItem(window.location.pathname, "true");
    }
    setOpen(false);
  };

  const openModalHandler = () => {
    localStorage.removeItem(window.location.pathname);
    setOpen(true);
  };

  const getFiltersLengthHandler = useCallback(() => {
    if (serviceProps !== undefined) {
      const keys = Object.keys(serviceProps);
      const keysWithValues = keys
        .filter((key) => serviceProps[key] !== null)
        .filter((key) => serviceProps[key] !== undefined);

      setFiltersLength(keysWithValues.length);
    } else {
      setFiltersLength(0);
    }
  }, [serviceProps]);

  useEffect(() => {
    getFiltersLengthHandler();
  }, [getFiltersLengthHandler]);

  return (
    <>
      <Modal
        open={open}
        onClose={closeModalHandler}
        title="Filtros"
        minWidth={300}
        maxWidth={350}
      >
        <FiltersModal
          applyFilters={applyFilters}
          cleanFilters={cleanFilters}
          filters={filters}
          textFilters={textFilters}
          onClose={closeModalHandler}
        />
      </Modal>
      <Stack flexDirection={"row"}>
        <Tooltip title="Filtros adicionais">
          <IconButton onClick={openModalHandler}>
            <Badge badgeContent={filtersLength} color="secondary">
              <FilterAltIcon color="primary" />
            </Badge>
          </IconButton>
        </Tooltip>
        {filtersLength !== 0 && (
          <Button
            variant="text"
            fullWidth
            onClick={() => {
              if (cleanFilters) cleanFilters();
              closeModalHandler();
            }}
          >
            Limpar filtros
          </Button>
        )}
      </Stack>
    </>
  );
};

export default Filters;
