import { Grid } from "@mui/material";
import { FormInput } from "../../../../components/UI/Form/FormTextInput/FormTextInput";
import { useFormContext } from "react-hook-form";
import { TUserForm } from "./UserSchema";

const UserCpf = () => {
  const { setValue } = useFormContext<TUserForm>();

  return (
    <Grid item xs={12} sm={6} mb={1}>
      <FormInput
        name="document"
        onBlur={(e) =>
          setValue(
            "login",
            e.target.value.replaceAll(".", "").replaceAll("-", "")
          )
        }
        label="CPF"
        size="small"
        fullWidth
        mask={"000.000.000-00"}
      />
    </Grid>
  );
};

export default UserCpf;
