import { Grid } from "@mui/material";
import { FormContainer } from "../../../../components/UI/Form/FormContainer";
import { FormInput } from "../../../../components/UI/Form/FormTextInput";
import { MainApiService } from "../../../../services/mainApi";
import { SwitchInput } from "../../../../components/UI/Form/Switch/Switch";
import { TRoleForm, roleSchema } from "./RoleSchema";
import { IRole } from "../../../../models/roles";
import { useCallback } from "react";
import { useFnRequest } from "../../../../hooks/useFnRequest/useFnRequest";

const ROLE_ROUTE = process.env.REACT_APP_API + "/api/role";
const roleService = new MainApiService<IRole>(ROLE_ROUTE);

const Role = () => {
  const { sendRequest: save, loading: sLoading } = useFnRequest(
    roleService.create
  );
  const { sendRequest: update, loading: uLoading } = useFnRequest(
    roleService.updateItemById
  );
  const { sendRequest: get, loading: gLoading } = useFnRequest(
    roleService.getItemById
  );

  const updateHandler = useCallback(
    (v: TRoleForm, id: string) => {
      return update({
        item: v,
        id,
      });
    },
    [update]
  );

  const loading = sLoading || uLoading || gLoading;

  return (
    <FormContainer<TRoleForm>
      title={"Papel de usuário"}
      saveFunction={save}
      updateFunction={updateHandler}
      getFunction={get}
      loading={loading}
      schema={roleSchema}
      subtitle={"Novo papel de usuário"}
      subtitleWatchField={"name"}
      fields={() => (
        <Grid container spacing={2}>
          <Grid item sm={12} textAlign={"end"}>
            <SwitchInput label="Ativar" name="enable" />
          </Grid>

          <Grid item sm={12}>
            <FormInput
              name="name"
              label="Nome"
              autoFocus
              size="small"
              fullWidth
            />
          </Grid>
        </Grid>
      )}
    />
  );
};

export default Role;
