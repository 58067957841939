import "./Editor.css";

import React, { useEffect } from "react";
import { EditorContent, useEditor } from "@tiptap/react";

import { Box } from "@mui/material";
import StarterKit from "@tiptap/starter-kit";
import Color from "@tiptap/extension-color";

import MenuBar from "./MenuBar";
import { IEditorComponent } from "./models";
import { useLocation } from "react-router-dom";

export const EditorComponent: React.FC<IEditorComponent> = ({
  onChange,
  value = "",
  error = false,
  readOnly = false,
  maxHeight = "600px",
  minHeight,
  menuBarProps = {
    fontSize: true,
    markFormatting: true,
    selectColor: true,
    textFormatting: true,
  },
}) => {
  const { pathname } = useLocation();

  const editor = useEditor({
    extensions: [
      Color,
      StarterKit.configure({
        bulletList: {
          keepMarks: true,
          keepAttributes: false,
        },
        orderedList: {
          keepMarks: true,
          keepAttributes: false,
        },
      }),
    ],
    content: value ? value : "",
    onUpdate: ({ editor }) => {
      if (pathname.includes("visualizar")) return;
      const html = editor.getHTML();
      if (onChange) {
        onChange(html);
      }
    },
    editable: !readOnly,
  });

  useEffect(() => {
    if (!editor?.isFocused) {
      editor?.commands.setContent(value);
    }
  }, [value, editor]);

  return (
    <Box
      sx={(theme) => ({
        border: "1px solid",
        borderRadius: theme.shape.borderRadius - 7,
        borderColor: error ? theme.palette.error.main : theme.palette.divider,
        overflow: "auto",
        maxHeight: maxHeight,
        position: "relative",
      })}
    >
      {!readOnly && (
        <Box
          padding={2}
          paddingBottom={0}
          sx={(theme) => ({
            position: "sticky",
            top: 0,
            zIndex: 2,
            backgroundColor: theme.palette.background.default,
            paddingBottom: "10px",
            borderBottom: "1px solid ",
            borderBottomColor: theme.palette.divider,
          })}
        >
          <MenuBar editor={editor} menuBarProps={menuBarProps} />
        </Box>
      )}

      <Box padding={2}>
        <EditorContent editor={editor} />
      </Box>
    </Box>
  );
};
