import { Button } from "@mui/material";
import React from "react";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import SortByAlphaIcon from "@mui/icons-material/SortByAlpha";

import { IMacroFilters } from "./models";

const MacroFilters: React.FC<IMacroFilters> = ({
  api,
  filters,
  ShowDefault,
}) => {
  return (
    <>
      {filters && filters(api)}

      {ShowDefault && (
        <Button startIcon={<SortByAlphaIcon />} variant="outlined">
          Ordem
        </Button>
      )}

      {ShowDefault && (
        <Button startIcon={<RemoveCircleOutlineIcon />}>Limpar filro</Button>
      )}
    </>
  );
};
export default MacroFilters;
