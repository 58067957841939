import Grid from "@mui/material/Grid";
import { useEffect, useRef, useState } from "react";
import { ITransferListProps } from "./models";

import { intersection, not, union } from "./filters";
import { ListSelectButtons } from "./ListSelectButtons/ListSelectButtons";
import ListSelect from "./ListSelect/ListSelect";
import { ISelectType } from "../../../models/requests";
import { SearchInput } from "./SearchInput";
import { useFetch } from "../../../hooks/useFetch";

export const TransferList = ({
  options,
  value,
  onChange,
  request = true,
  software,
  listRequest,
}: ITransferListProps) => {
  const countRef = useRef(0); // Usando useRef para manter o valor entre renderizações
  const renderedRef = useRef(false); // Controla se o componente já foi renderizado
  //  --------------- Barras de pesquisa
  const [searchprivilegesAvailable, setSearchprivilegesAvailable] =
    useState<string>();

  const [searchprivilegesAdded, setSearchprivilegesAdded] = useState<string>();
  // --------------------

  // ---------------- Altera fields com base no que foi pesquisado
  const [filteredPrivilegesAvailable, setFilteredPrivilegesAvailable] =
    useState<ISelectType[]>([]);

  const [filteredPrivilegesAdded, setFilteredPrivilegesAdded] = useState<
    ISelectType[]
  >([]);
  // ---------------------

  //------------------ Seleciona os clicados
  const [checked, setChecked] = useState<ISelectType[]>([]);
  // -------------------

  const [privilegesAvailable, setPrivilegesAvailable] = useState<ISelectType[]>(
    options || []
  );
  const [privilegesAdded, setPrivilegesAdded] = useState<ISelectType[]>([]);

  const { loading, sendRequest } = useFetch(listRequest);

  useEffect(() => {
    if (renderedRef.current) {
      countRef.current += 1;
      if (countRef.current >= 1) {
        onChange([]);
      }
    } else {
      renderedRef.current = true;
    }
  }, [software, onChange]);

  useEffect(() => {
    const loadRequest = async () => {
      const { data, success } = await sendRequest(null);
      if (success) {
        const realData: ISelectType[] = data.map((d) => d);
        setPrivilegesAvailable(() => {
          const filterPrivAvailable = realData.filter((o) =>
            value?.every((v) => v.id !== o.id)
          );
          return filterPrivAvailable;
        });
      }
    };
    loadRequest();
  }, [sendRequest, value]);

  useEffect(() => {
    if (value) {
      setPrivilegesAdded(value);
    }
  }, [value, setPrivilegesAdded]);

  //  ------------------------------ Somente para a parte de pesquisa
  useEffect(() => {
    setFilteredPrivilegesAvailable(
      privilegesAvailable.filter((item) => {
        if (item.code) {
          return item.code
            ?.toLowerCase()
            .includes(searchprivilegesAvailable?.toLowerCase() || "");
        }
        return item.description
          ?.toLowerCase()
          .includes(searchprivilegesAvailable?.toLowerCase() || "");
      })
    );
    setFilteredPrivilegesAdded(
      privilegesAdded.filter((item) => {
        if (item.code) {
          return item.code
            ?.toLowerCase()
            .includes(searchprivilegesAdded?.toLowerCase() || "");
        }
        return item.description
          ?.toLowerCase()
          .includes(searchprivilegesAdded?.toLowerCase() || "");
      })
    );
    if (searchprivilegesAvailable?.length === 0)
      setFilteredPrivilegesAvailable([]);
    if (searchprivilegesAdded?.length === 0) setFilteredPrivilegesAdded([]);
  }, [
    searchprivilegesAvailable,
    searchprivilegesAdded,
    privilegesAdded,
    privilegesAvailable,
  ]);
  // ---------------------------

  const privilegesAvailableChecked = intersection(checked, privilegesAvailable);
  const privilegesAddedChecked = intersection(checked, privilegesAdded);

  const handleToggle = (value: ISelectType) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items: ISelectType[]) =>
    intersection(checked, items).length;

  const handleToggleAll = (items: ISelectType[]) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedprivilegesAdded = () => {
    setPrivilegesAdded([...checked, ...privilegesAdded]);
    setPrivilegesAvailable(
      not(privilegesAvailable, privilegesAvailableChecked)
    );
    setChecked(not(checked, privilegesAvailableChecked));
    onChange([...checked, ...privilegesAdded]);
  };

  const handleCheckedprivilegesAvailable = () => {
    setPrivilegesAvailable(privilegesAvailable.concat(privilegesAddedChecked));
    setPrivilegesAdded(not(privilegesAdded, privilegesAddedChecked));
    setChecked(not(checked, privilegesAddedChecked));
    const removePrivilegesAv = [...privilegesAdded].filter(
      (item) => ![...checked].includes(item)
    );
    onChange(removePrivilegesAv);
  };

  return (
    <Grid container spacing={2} justifyContent="flex-start" alignItems="center">
      <Grid item>
        <ListSelect
          checked={checked}
          title={"Privilégios"}
          items={
            filteredPrivilegesAvailable.length > 0
              ? filteredPrivilegesAvailable
              : privilegesAvailable
          }
          handleToggle={handleToggle}
          handleToggleAll={handleToggleAll}
          numberOfChecked={numberOfChecked}
          loading={loading}
        >
          <SearchInput
            onSearch={(term) => {
              setSearchprivilegesAvailable(term);
            }}
            placeholder="Pesquisar privilégios"
            focus={false}
          />
        </ListSelect>
      </Grid>
      <ListSelectButtons
        handleCheckedAvailable={handleCheckedprivilegesAvailable}
        handleCheckedAdded={handleCheckedprivilegesAdded}
        availableChecked={privilegesAvailableChecked.length}
        addedChecked={privilegesAddedChecked.length}
      />
      <Grid item>
        <ListSelect
          checked={checked}
          title={"Privilégios adicionados"}
          items={
            filteredPrivilegesAdded.length > 0
              ? filteredPrivilegesAdded
              : privilegesAdded
          }
          handleToggle={handleToggle}
          handleToggleAll={handleToggleAll}
          numberOfChecked={numberOfChecked}
          loading={loading}
        >
          <SearchInput
            onSearch={(term) => {
              setSearchprivilegesAdded(term);
            }}
            placeholder="Pesquisar privilégios"
            focus={false}
          />
        </ListSelect>
      </Grid>
    </Grid>
  );
};
