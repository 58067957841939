import { GlobalAxios } from "../axios/axios-config";
import { IUser } from "../contexts/User-Context";
import { IPrivilege, IUseChangePasswordParams } from "../models/user";

const USER_NAME_ID = "username";
const PERMISSION_ID = "permissions";

export const getUserName = () => localStorage.getItem(USER_NAME_ID);

export const saveUserName = (username: string) =>
  localStorage.setItem(USER_NAME_ID, username);

export const removeUserName = () => localStorage.removeItem(USER_NAME_ID);

export const BASE_URL = process.env.REACT_APP_API;

export const userMe = () => GlobalAxios.get<IUser>(BASE_URL + "/api/user/me");

export const savePermissions = (privileges: IPrivilege[]) =>
  localStorage.setItem(PERMISSION_ID, JSON.stringify(privileges));

export const removePermissions = () => localStorage.removeItem(PERMISSION_ID);

export const userChangePassword = (params?: IUseChangePasswordParams) => {
  return GlobalAxios.post(BASE_URL + "/api/user/changePassword", params);
};
