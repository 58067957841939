import { z } from "zod";

export const helpContentSchema = z.object({
  id: z.string().optional(),
  enable: z.boolean(),
  title: z.string().min(1, "O título é obrigatório"),
  content: z.string().min(1, "O conteúdo é obrigatório"),
});

export type THelpContentForm = z.infer<typeof helpContentSchema>;
