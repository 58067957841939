import { FC } from "react";
import { SvgIconProps } from "@mui/material";

import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import EventRoundedIcon from "@mui/icons-material/EventRounded";
import GroupsRoundedIcon from "@mui/icons-material/GroupsRounded";
import GroupRoundedIcon from "@mui/icons-material/GroupRounded";
import FolderSharedRoundedIcon from "@mui/icons-material/FolderSharedRounded";
import PersonSearchRoundedIcon from "@mui/icons-material/PersonSearchRounded";
import RedeemRoundedIcon from "@mui/icons-material/RedeemRounded";
import MonitorRoundedIcon from "@mui/icons-material/MonitorRounded";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import ContactPageRoundedIcon from "@mui/icons-material/ContactPageRounded";
import AccountBoxRoundedIcon from "@mui/icons-material/AccountBoxRounded";
import AppRegistrationRoundedIcon from "@mui/icons-material/AppRegistrationRounded";
import DomainRoundedIcon from "@mui/icons-material/DomainRounded";
import CorporateFareRoundedIcon from "@mui/icons-material/CorporateFareRounded";
import HomeRepairServiceRoundedIcon from "@mui/icons-material/HomeRepairServiceRounded";
import ThumbUpRoundedIcon from "@mui/icons-material/ThumbUpRounded";
import AccountBalanceRoundedIcon from "@mui/icons-material/AccountBalanceRounded";
import SchoolRoundedIcon from "@mui/icons-material/SchoolRounded";
import InstallMobileRoundedIcon from "@mui/icons-material/InstallMobileRounded";
import CampaignRoundedIcon from "@mui/icons-material/CampaignRounded";
import BuildRoundedIcon from "@mui/icons-material/BuildRounded";
import StorageRoundedIcon from "@mui/icons-material/StorageRounded";
import QueueRoundedIcon from "@mui/icons-material/QueueRounded";
import AdminPanelSettingsRoundedIcon from "@mui/icons-material/AdminPanelSettingsRounded";
import QueryStatsRoundedIcon from "@mui/icons-material/QueryStatsRounded";
import PeopleOutlineRoundedIcon from "@mui/icons-material/PeopleOutlineRounded";
import FolderOpenRoundedIcon from "@mui/icons-material/FolderOpenRounded";
import ConnectWithoutContactRoundedIcon from "@mui/icons-material/ConnectWithoutContactRounded";
import TroubleshootRoundedIcon from "@mui/icons-material/TroubleshootRounded";
import SupportAgentRoundedIcon from "@mui/icons-material/SupportAgentRounded";
import HelpCenterRoundedIcon from "@mui/icons-material/HelpCenterRounded";
import DriveFolderUploadRoundedIcon from "@mui/icons-material/DriveFolderUploadRounded";
import PaidRoundedIcon from "@mui/icons-material/PaidRounded";
import ManageAccountsRoundedIcon from "@mui/icons-material/ManageAccountsRounded";
import Diversity3RoundedIcon from "@mui/icons-material/Diversity3Rounded";

interface IDynamicIcon {
  icon?: string | null;
}

const DynamicIcon: FC<SvgIconProps & IDynamicIcon> = ({ icon }, props) => {
  switch (icon) {
    case "HomeRoundedIcon":
      return <HomeRoundedIcon {...props} />;
    case "EventRoundedIcon":
      return <EventRoundedIcon {...props} />;
    case "GroupsRoundedIcon":
      return <GroupsRoundedIcon {...props} />;
    case "GroupRoundedIcon":
      return <GroupRoundedIcon {...props} />;
    case "FolderSharedRoundedIcon":
      return <FolderSharedRoundedIcon {...props} />;
    case "PersonSearchRoundedIcon":
      return <PersonSearchRoundedIcon {...props} />;
    case "RedeemRoundedIcon":
      return <RedeemRoundedIcon {...props} />;
    case "MonitorRoundedIcon":
      return <MonitorRoundedIcon {...props} />;
    case "PersonRoundedIcon":
      return <PersonRoundedIcon {...props} />;
    case "ContactPageRoundedIcon":
      return <ContactPageRoundedIcon {...props} />;
    case "AccountBoxRoundedIcon":
      return <AccountBoxRoundedIcon {...props} />;
    case "AppRegistrationRoundedIcon":
      return <AppRegistrationRoundedIcon {...props} />;
    case "DomainRoundedIcon":
      return <DomainRoundedIcon {...props} />;
    case "CorporateFareRoundedIcon":
      return <CorporateFareRoundedIcon {...props} />;
    case "HomeRepairServiceRoundedIcon":
      return <HomeRepairServiceRoundedIcon {...props} />;
    case "ThumbUpRoundedIcon":
      return <ThumbUpRoundedIcon {...props} />;
    case "AccountBalanceRoundedIcon":
      return <AccountBalanceRoundedIcon {...props} />;
    case "SchoolRoundedIcon":
      return <SchoolRoundedIcon {...props} />;
    case "InstallMobileRoundedIcon":
      return <InstallMobileRoundedIcon {...props} />;
    case "CampaignRoundedIcon":
      return <CampaignRoundedIcon {...props} />;
    case "BuildRoundedIcon":
      return <BuildRoundedIcon {...props} />;
    case "StorageRoundedIcon":
      return <StorageRoundedIcon {...props} />;
    case "QueueRounded":
      return <QueueRoundedIcon {...props} />;
    case "AdminPanelSettingsRoundedIcon":
      return <AdminPanelSettingsRoundedIcon {...props} />;
    case "QueryStatsRoundedIcon":
      return <QueryStatsRoundedIcon {...props} />;
    case "PeopleOutlineRoundedIcon":
      return <PeopleOutlineRoundedIcon {...props} />;
    case "FolderOpenRoundedIcon":
      return <FolderOpenRoundedIcon {...props} />;
    case "ConnectWithoutContactRoundedIcon":
      return <ConnectWithoutContactRoundedIcon {...props} />;
    case "TroubleshootRoundedIcon":
      return <TroubleshootRoundedIcon {...props} />;
    case "SupportAgentRoundedIcon":
      return <SupportAgentRoundedIcon {...props} />;
    case "HelpCenterRoundedIcon":
      return <HelpCenterRoundedIcon {...props} />;
    case "DriveFolderUploadRoundedIcon":
      return <DriveFolderUploadRoundedIcon {...props} />;
    case "PaidRoundedIcon":
      return <PaidRoundedIcon {...props} />;
    case "ManageAccountsRoundedIcon":
      return <ManageAccountsRoundedIcon {...props} />;
    case "Diversity3RoundedIcon":
      return <Diversity3RoundedIcon {...props} />;
    default:
      return null;
  }
};

export default DynamicIcon;
