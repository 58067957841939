import React from "react";
import {
  Button,
  Chip,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useRef } from "react";
// import { useDraggable } from "react-use-draggable-scroll";

const ParamsFilter = ({
  filterItems,
  onDelete,
  cleanFilter,
}: {
  filterItems: string[];
  onDelete: (item: string) => void;
  cleanFilter: () => void;
}) => {
  const handleDelete = (item: any) => {
    onDelete(item);
  };
  const ref =
    useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>;
  // const { events } = useDraggable(ref);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <Stack
        display={"flex"}
        flexDirection={matches ? "column" : "row"}
        alignItems={"center"}
        gap={1}
        height={"100%"}
        width={matches && filterItems.length >= 3 ? "100%" : "fit-content"}
        minWidth={"200px"}
        sx={{
          overflowX: "auto",
          "&::-webkit-scrollbar": { display: "none" },
          scrollbarWidth: "none",
        }}
        ref={ref}
        // {...events}
      >
        {matches && <Typography variant="h6">Filtros</Typography>}
        {filterItems.map((item) => (
          <Chip
            size="small"
            key={item}
            label={item}
            onDelete={() => handleDelete(item)}
          />
        ))}
      </Stack>
      <Button startIcon={<CloseIcon />} size="small" onClick={cleanFilter}>
        Limpar filtro
      </Button>
    </>
  );
};

export default ParamsFilter;
