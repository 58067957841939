import { useCallback, useMemo, useState } from "react";
import { IUseUploadFileProps } from "./models";
import UploadFileService from "../../services/uploadFileService/uploadFile.service";
import { IUploadFileProps } from "../../services/uploadFileService";
import { useFnRequest } from "../useFnRequest/useFnRequest";

/**
 *
 * An hook to send files
 *
 * @param param an object containing an axios instance and a path route
 * @returns data about the file upload process
 */
const useUploadFile = ({ axios, fileRoute }: IUseUploadFileProps) => {
  const [progress, setProgress] = useState<number | null>(null);
  const [imagePreview, setImagePreview] = useState<string>();
  const [fileId, setFileId] = useState<string>();
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const service = useMemo(
    () => new UploadFileService(axios, fileRoute),
    [axios, fileRoute]
  );

  const onGetProgress = (uploadProgress: number) => {
    setProgress(uploadProgress);
  };

  const { sendRequest: upload, loading } = useFnRequest(service.upload);

  /**
   * Cancel the actual file upload
   */
  const cancelRequest = () => {
    service.cancel();
  };

  /**
   * An function to upload the file
   */
  const uploadFile = useCallback(
    async ({
      mediaTypeIdentifier,
      file,
      mediaTypeCode,
      parentName,
      type,
    }: IUploadFileProps) => {
      const { data, success } = await upload({
        data: {
          type,
          parentName,
          mediaTypeCode,
          file,
          mediaTypeIdentifier,
        },
        progressCallback: onGetProgress,
      });
      if (success) {
        setProgress(0);
        setError(false);
        setSuccess(true);
        setImagePreview(URL.createObjectURL(file));
        setFileId(data.id);
      } else {
        setProgress(0);
        setError(true);
        setSuccess(false);
      }
    },
    [upload]
  );

  return {
    /**
     * The uploade progress
     */
    progress,
    /**
     * An imagePreview link
     */
    imagePreview,
    /**
     * File uploaded id
     */
    fileId,
    /**
     * An boolean if the request was successful
     */
    success,
    /**
     * An boolean if the request had an error
     */
    error,
    uploadFile,
    cancelRequest,
    loading,
  };
};

export default useUploadFile;
