import { Grid } from "@mui/material";
import { AutoComplete } from "../../../../../components/UI/Form/Autocomplete";
import { useCallback, useEffect, useState } from "react";
import { ISelectType } from "../../../../../contexts/Config-Context";
import { useFormContext, useWatch } from "react-hook-form";
import { TEstabilishmentForm } from "../EstabilishmentSchema";

interface IEstablishmentMailMethodProps {
  index: number;
  data: ISelectType[];
  loadDefaultMethod: (method: ISelectType) => void;
}

const EstablishmentMailMethod = ({
  index,
  data,
  loadDefaultMethod,
}: IEstablishmentMailMethodProps) => {
  const { control } = useFormContext<TEstabilishmentForm>();

  const [mailMethodOptions, setMailMethodOptions] = useState<ISelectType[]>([]);

  const selectedMethod = useWatch({
    name: "mailMethods",
    control,
  });

  const filterMailMethodOptions = useCallback(() => {
    if (!selectedMethod) return;

    const selectedMailMethod = selectedMethod.find(
      (lang, indexLang) => indexLang === index
    );
    const filteredOptions = data.filter(
      (objeto2) =>
        !selectedMethod.some((objeto1) => objeto1.method?.id === objeto2.id)
    );

    loadDefaultMethod(filteredOptions[0]);

    if (selectedMailMethod?.method && filteredOptions.length !== 0) {
      setMailMethodOptions([
        ...filteredOptions,
        {
          id: selectedMailMethod.method.id,
          name: selectedMailMethod.method.name,
        },
      ]);
    } else {
      setMailMethodOptions(filteredOptions);
    }

  }, [data, index, selectedMethod, loadDefaultMethod]);

  useEffect(() => {
    filterMailMethodOptions();
  }, [filterMailMethodOptions]);

  return (
    <Grid item xs={12} sm={4} mb={1}>
      <AutoComplete
        name={`mailMethods.${index}.method`}
        label={"Tipo de email"}
        getOptionLabel={(option) => {
          return option.name;
        }}
        size="small"
        options={mailMethodOptions || []}
        canClear={false}
      />
    </Grid>
  );
};

export default EstablishmentMailMethod;
