import { FormControlLabel, Switch } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { ISwitchInput } from "./models";
import { useLocation } from "react-router-dom";

export const SwitchInput = ({
  label,
  name,
  defaultValue = true,
  onSwitched,
}: ISwitchInput) => {
  const { control } = useFormContext();
  const { pathname } = useLocation();

  return (
    <Controller
      render={({ field }) => {
        const { onChange, value } = field;
        return (
          <FormControlLabel
            control={
              <Switch
                checked={value}
                onChange={(e) => {
                  if (!pathname.includes("visualizar")) {
                    if (onSwitched) {
                      onSwitched(e.target.checked);
                    }
                    onChange(e.target.checked);
                  }
                }}
                color={pathname.includes("visualizar") ? "default" : "primary"}
              />
            }
            label={label}
            {...field}
          />
        );
      }}
      defaultValue={defaultValue}
      name={name}
      control={control}
    />
  );
};
