import { Grid, IconButton, InputAdornment } from "@mui/material";
import { FormContainer } from "../../../../components/UI/Form/FormContainer";
import { FormInput } from "../../../../components/UI/Form/FormTextInput";
import { SwitchInput } from "../../../../components/UI/Form/Switch/Switch";
import { TUserForm, userSchema } from "./UserSchema";
import UserCpf from "./UserCpf";
import UsersService from "../../../../services/users.service";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useState } from "react";
import { MainApiService } from "../../../../services/mainApi";
import { ISelectType } from "../../../../contexts/Config-Context";
import { AutoCompleteMulti } from "../../../../components/UI/Form/AutocompleteMulti";
import UserProfilesService from "../../../../services/userProfiles.service";
import { useRole } from "../../../../hooks/useRole";
import { useFnRequest } from "../../../../hooks/useFnRequest/useFnRequest";

const usersService = new UsersService();

const ROLES_ROUTE = process.env.REACT_APP_API + "/api/role/";
const rolesService = new MainApiService<ISelectType>(ROLES_ROUTE);

const ROLES_HIDDEN_ROUTE = process.env.REACT_APP_API + "/api/role/hidden/";
const rolesHiddenService = new MainApiService<ISelectType>(ROLES_HIDDEN_ROUTE);

const userProfilesService = new UserProfilesService();

const PROFILES_HIDDEN_ROUTE =
  process.env.REACT_APP_API + "/api/profile/hidden/";
const profilesHiddenService = new MainApiService<ISelectType>(
  PROFILES_HIDDEN_ROUTE
);

const User = () => {
  const [showPassword, setShowPassword] = useState(false);
  const { sendRequest: save, loading: sLoading } = useFnRequest(
    usersService.create
  );
  const hasRolePermission = useRole(["Execute"], "user-hidden-role-execute");
  const hasProfilePermission = useRole(
    ["Execute"],
    "user-hidden-profile-execute"
  );

  const { sendRequest: update, loading: uLoading } = useFnRequest(
    usersService.updateItemById
  );

  const { sendRequest: get, loading: gLoading } = useFnRequest(
    usersService.getItemById
  );

  const saveHandler = (v: TUserForm) => {
    return save({
      ...v,
      password: v.password!,
      roleIds: v.roles.map((role) => role.id),
      roleHiddenIds:
        hasRolePermission && v.roleHiddens
          ? v.roleHiddens.map((role) => role.id)
          : undefined,
      profileIds: v.profiles.map((profile) => profile.id),
      profileHiddenIds:
        hasRolePermission && v.profileHiddens
          ? v.profileHiddens.map((profile) => profile.id)
          : undefined,
    });
  };

  const updateHandler = (v: TUserForm, id: string) => {
    return update({
      item: {
        ...v,
        roleIds: v.roles.map((role) => role.id),
        roleHiddenIds:
          hasRolePermission && v.roleHiddens
            ? v.roleHiddens.map((role) => role.id)
            : undefined,
        profileIds: v.profiles.map((profile) => profile.id),
        profileHiddenIds:
          hasRolePermission && v.profileHiddens
            ? v.profileHiddens.map((profile) => profile.id)
            : undefined,
      },
      id,
    });
  };

  const loading = sLoading || uLoading || gLoading;

  return (
    <FormContainer<TUserForm>
      title={"Usuário"}
      saveFunction={saveHandler}
      updateFunction={updateHandler}
      getFunction={get}
      loading={loading}
      schema={userSchema}
      subtitle={"Novo usuário"}
      subtitleWatchField={"name"}
      fields={(type) => (
        <Grid container spacing={2}>
          <Grid item sm={12} textAlign={"end"}>
            <SwitchInput label="Ativar" name="enable" />
          </Grid>

          <Grid item xs={12} sm={6} mb={1}>
            <FormInput
              name="name"
              label="Nome"
              autoFocus
              size="small"
              fullWidth
            />
          </Grid>

          <UserCpf />

          <Grid item xs={12} sm={6} mb={1}>
            <FormInput name="login" label="Login" size="small" fullWidth />
          </Grid>

          {type === "create" && (
            <Grid item xs={12} sm={6} mb={1}>
              <FormInput
                name="password"
                label="Senha"
                size="small"
                type={showPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {!showPassword && <RemoveRedEyeIcon />}
                      {showPassword && <VisibilityOffIcon />}
                    </IconButton>
                  </InputAdornment>
                }
                fullWidth
              />
            </Grid>
          )}

          <Grid item xs={12} sm={12} mb={1}>
            <FormInput name="mail" label="E-mail" size="small" fullWidth />
          </Grid>

          <Grid
            item
            xs={12}
            sm={
              hasRolePermission
                ? 6
                : !hasRolePermission && !hasProfilePermission
                ? 6
                : 12
            }
            mb={1}
          >
            <AutoCompleteMulti
              name="roles"
              label="Papéis de usuário"
              size="small"
              request={rolesService.list}
            />
          </Grid>

          {hasRolePermission && (
            <Grid item xs={12} sm={6} mb={1}>
              <AutoCompleteMulti
                name="roleHiddens"
                label="Papéis de usuário ocultos"
                size="small"
                request={rolesHiddenService.list}
              />
            </Grid>
          )}

          <Grid
            item
            xs={12}
            sm={
              hasProfilePermission
                ? 6
                : !hasRolePermission && !hasProfilePermission
                ? 6
                : 12
            }
            mb={1}
          >
            <AutoCompleteMulti
              name="profiles"
              label="Perfis de usuário"
              size="small"
              request={userProfilesService.list}
            />
          </Grid>

          {hasProfilePermission && (
            <Grid item xs={12} sm={6} mb={1}>
              <AutoCompleteMulti
                name="profileHiddens"
                label="Perfis de usuário ocultos"
                size="small"
                request={profilesHiddenService.list}
              />
            </Grid>
          )}
        </Grid>
      )}
    />
  );
};

export default User;
