import { AxiosInstance } from "axios";
import { IUploadFile } from "./models";

/**
 * An uploadFile Service
 *
 *
 */
export class UploadFileService {
  axios: AxiosInstance;
  fileRoute: string;
  controller = new AbortController();

  /**
   *
   * @param ax An axios instance
   * @param fr The route for axios request
   */
  constructor(ax: AxiosInstance, fr: string) {
    this.axios = ax;
    this.fileRoute = fr;
  }

  /**
   * Cancel the request using axios controller
   */
  cancel = () => {
    this.controller.abort();
    this.controller = new AbortController();
  };

  /**
   * Upload the file using the provided file props and parsing it to form data
   *
   * @param item Upload file item
   * @returns the axios request utilizing formdata
   */
  upload = (item: IUploadFile) => {
    const formData = new FormData();
    formData.append("file", item.data.file);
    formData.append("mediaTypeCode", item.data.mediaTypeCode);
    formData.append("parentName", item.data.parentName);
    formData.append(
      "isPublic",
      JSON.stringify(item.data.type === "Public" ? true : false)
    );
    formData.append("mediaTypeIdentifier", item.data.mediaTypeIdentifier);

    return this.axios.post(this.fileRoute, formData, {
      signal: this.controller.signal,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: (event) => {
        if (event.total) {
          let progress: number = Math.round((event.loaded * 100) / event.total);
          item.progressCallback(progress);
        }
      },
    });
  };
}

export default UploadFileService;
