import { Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import Logo from "../Menu/Logo";

const Footer = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Stack
      height={40}
      width={"100%"}
      position={"fixed"}
      bottom={0}
      zIndex={100}
      sx={(theme) => ({
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        gap: 2,
        backgroundColor:
          theme.palette.mode === "light"
            ? theme.palette.grey["200"]
            : theme.palette.background.paper,
      })}
    >
      <Logo
        logoWidth={150}
        src="/dimdim_logo.png"
        alt="Logo DimDim"
        link="https://www.projeto-modelo.com.br"
      />
      {!matches && (
        <Typography variant="subtitle2">
          &copy; DimDim - {new Date().getFullYear()} | Todos os direitos
          reservados
        </Typography>
      )}
    </Stack>
  );
};

export default Footer;
