import { RouteObject } from "react-router-dom";
import { DomainProvider } from "../contexts/Domain-Context";
import Estabilishment from "../pages/Registers/Establishments/EstabilishmentsForm/Estabilishment";
import EstablishmentsPage from "../pages/Registers/Establishments/EstablishmentsPage";
import HelpContent from "pages/Registers/HelpContent/HelpContentForm/HelpContent";
import HelpContentPage from "pages/Registers/HelpContent/HelpContentPage";

const AdminRoutes: RouteObject[] = [
  {
    path: "estabelecimentos",
    element: (
      <DomainProvider value={{ domain: "establishment" }}>
        <EstablishmentsPage />
      </DomainProvider>
    ),
  },
  {
    path: "estabelecimentos/novo",
    element: (
      <DomainProvider value={{ domain: "establishment" }} actions={["Create"]}>
        <Estabilishment />
      </DomainProvider>
    ),
  },
  {
    path: "estabelecimentos/:id/editar",
    element: (
      <DomainProvider value={{ domain: "establishment" }} actions={["Update"]}>
        <Estabilishment />
      </DomainProvider>
    ),
  },
  {
    path: "estabelecimentos/:id/visualizar",
    element: (
      <DomainProvider value={{ domain: "establishment" }}>
        <Estabilishment />
      </DomainProvider>
    ),
  },
  {
    path: "conteudos-ajuda",
    element: (
      <DomainProvider value={{ domain: "help-content" }}>
        <HelpContentPage />
      </DomainProvider>
    ),
  },
  {
    path: "conteudos-ajuda/novo",
    element: (
      <DomainProvider value={{ domain: "help-content" }} actions={["Create"]}>
        <HelpContent />
      </DomainProvider>
    ),
  },
  {
    path: "conteudos-ajuda/:id/editar",
    element: (
      <DomainProvider value={{ domain: "help-content" }} actions={["Update"]}>
        <HelpContent />
      </DomainProvider>
    ),
  },
  {
    path: "conteudos-ajuda/:id/visualizar",
    element: (
      <DomainProvider value={{ domain: "help-content" }}>
        <HelpContent />
      </DomainProvider>
    ),
  },
];

export default AdminRoutes;
