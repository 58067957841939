import React from "react";
import { Badge, Avatar as MuiAvatar, Typography } from "@mui/material";
import DomainIcon from "@mui/icons-material/Domain";

import { IEstablishmentBadgeProps } from "./models";
import { getInitials, stringToColor } from "../../../utils";

const EstablishimentBadge: React.FC<IEstablishmentBadgeProps> = ({
  establishment,
}) => {
  return (
    <Badge
      overlap="circular"
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      badgeContent={
        establishment && (
          <MuiAvatar
            sx={(theme) => ({
              bgcolor: stringToColor(establishment.id),
              width: 20,
              height: 20,
              color: theme.palette.getContrastText(
                stringToColor(establishment.id)
              ),
            })}
          >
            <Typography variant="subtitle2">
              {getInitials(establishment.name)}
            </Typography>
          </MuiAvatar>
        )
      }
    >
      <DomainIcon color="inherit" fontSize="medium" />
    </Badge>
  );
};
export default EstablishimentBadge;
