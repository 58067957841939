import { GlobalAxios } from "../axios/axios-config";
import { ISelectType } from "../models/requests";
import { INewUser, IUser } from "../models/users";
import { TUserForm } from "../pages/AdmSystem/Users/UserForm/UserSchema";

const USERS_ROUTE = process.env.REACT_APP_API + "/api/user";
const GENERATE_TOKEN_ROUTE =
  process.env.REACT_APP_API + "/api/authentication/generateToken";

export class UsersService {
  create = (item: INewUser) => {
    return GlobalAxios.post(USERS_ROUTE, item);
  };

  getItemById = (id: string) => {
    return GlobalAxios.get<TUserForm>(USERS_ROUTE + "/" + id);
  };

  updateItemById = ({ item, id }: { item: IUser; id: string }) => {
    return GlobalAxios.put(USERS_ROUTE + "/" + id, item);
  };

  list = (params?: any) => {
    return GlobalAxios.get<ISelectType[]>(USERS_ROUTE + "list", {
      params: params,
    });
  };

  changePassword = ({ password, id }: { password: any; id: string }) => {
    return GlobalAxios.patch(USERS_ROUTE + "/password/" + id, {
      password: password,
    });
  };

  generateToken = (user_id: string) => {
    return GlobalAxios.post(GENERATE_TOKEN_ROUTE, {
      user_id: user_id,
    });
  };
}

export default UsersService;
