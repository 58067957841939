import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { Box, FormHelperText } from "@mui/material";

import { IFormTextEditor } from "./models";
import { EditorComponent } from "../TextEditor";
import { useLocation } from "react-router-dom";

export const FormTextEditor: React.FC<IFormTextEditor> = ({
  name,
  onlyCode,
  minHeight,
  maxHeight,
  menuBarProps = {
    fontSize: true,
    markFormatting: true,
    selectColor: true,
    textFormatting: true,
  },
}) => {
  const { control } = useFormContext();
  const { pathname } = useLocation();

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={""}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <Box>
          <EditorComponent
            onChange={onChange}
            value={value}
            error={!!error}
            menuBarProps={menuBarProps}
            readOnly={pathname.includes("visualizar")}
            minHeight={minHeight}
            maxHeight={maxHeight}
          />
          <FormHelperText error>{error ? error?.message : ""}</FormHelperText>
        </Box>
      )}
    />
  );
};
