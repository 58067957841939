import { Grid } from "@mui/material";
import { InputFileForm } from "../../../../../components/UI/Form/InputFile";
import { GlobalAxios } from "../../../../../axios/axios-config";
import { IInputFile } from "../../../../../components/UI/InputFile";
import { useFormContext, useWatch } from "react-hook-form";
import { TEstabilishmentForm } from "../EstabilishmentSchema";

const FILE_ROUTE = process.env.REACT_APP_API + "/api/file/upload";

interface IEstablishmentFileProps {
  index: number;
  onDeleteFileHandler: (file: IInputFile) => void;
}

const EstablishmentFile = ({
  index,
  onDeleteFileHandler
}: IEstablishmentFileProps) => {
  const { control } = useFormContext<TEstabilishmentForm>();

  const mediaType = useWatch({
    name: `establishmentMediaFiles.${index}.mediaType`,
    control,
  });

  const formats = ".jpg, .jpeg, .png, .gif";

  const resolution = mediaType.code === "3" ? "500x500" : "930x260";

  const information = `Formatos: ${formats}; Tamanho máximo: 5mb; Resolução: ${resolution}`;

  return (
    <Grid item xs={12} sm={9} mb={1}>
      <InputFileForm
        name={`establishmentMediaFiles.${index}.file`}
        axios={GlobalAxios}
        fileRoute={FILE_ROUTE}
        resolution={
          mediaType.code === "3"
            ? { width: 500, height: 500 }
            : { width: 930, height: 260 }
        }
        information={
          mediaType.code === "2"
            ? information +
              "; Este logo será usado no tema escuro da ferramenta"
            : information
        }
        fileInputProps={{
          accept: formats,
          label: "Arquivo",
          maxFileSize: 5,
          mediaTypeCode: mediaType.code || "1",
          mediaTypeIdentifier: "MEDIA_TYPE_ESTABLISHMENT",
          parentName: "Establishment",
          type: "Public",
          onFileSelected: onDeleteFileHandler,
          inputProps: {
            size: "small",
          },
        }}
      />
    </Grid>
  );
};

export default EstablishmentFile;
