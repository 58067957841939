import { Table } from "../../../components/UI/Table";
import { GridColDef } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { IRole } from "../../../models/roles";
import MainApiService from "../../../services/mainApi/mainApi.service";
import { Chip } from "@mui/material";

const ROLES_ROUTE = process.env.REACT_APP_API + "/api/role";
const rolesService = new MainApiService<IRole>(ROLES_ROUTE);

const RolesPage = () => {
  const navigate = useNavigate();

  const columns: GridColDef<IRole>[] = [
    {
      field: "name",
      headerName: "Nome",
      flex: 1,
    },
    {
      field: "enable",
      headerName: "Ativo",
      flex: 1,
      renderCell: (params) => {
        return (
          <Chip
            color={params.value ? "success" : "error"}
            size="small"
            label={params.value ? "Ativo" : "Inativo"}
          />
        );
      },
    },
  ];

  const addHanlder = () => navigate("novo");

  return (
    <Table<IRole>
      service={rolesService}
      showDefaultMacroFilters={false}
      columns={columns}
      title={"Papéis de usuário"}
      searchInput
      onAdd={addHanlder}
      searchInputPlaceHolder={"Pesquisar papel de usuário"}
      addButtonLabel={"Adicionar papel de usuário"}
      confirmOptions={{
        title: "Deletar papel de usuário?",
        description: "Isso vai permanentemente deletar o papel de usuário",
        confirmationButtonProps: {
          variant: "contained",
        },
      }}
    />
  );
};

export default RolesPage;
