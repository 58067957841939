import React from "react";
import { MuiFileInput } from "mui-file-input";
import { useCallback, useEffect, useState } from "react";
import { Stack, Typography } from "@mui/material";

import { IInputFileProps } from "./models";
import InputFileStatus from "./InputFileStatus/InputFileStatus";
import InputFileValidations from "./InputFileValidations/InputFileValidations";
import useUploadFile from "../../../hooks/useUploadFile/useUploadFile";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import InputFileResolutionMessage from "./InputFileResolutionMessage/InputFileResolutionMessage";
import InputFileInformations from "./InputFileInformations.tsx/InputFileInformations";

export const InputFile: React.FC<IInputFileProps> = ({
  value,
  onChange,
  label,
  accept,
  maxSize,
  uploadFileProps,
  oldValue,
  onFileSelected,
  size = "small",
  axios,
  fileRoute,
  disable = false,
  resolution,
  information,
}) => {
  const [file, setFile] = useState<File | null>(
    value
      ? new File([JSON.stringify(value)], value?.fileName || "", {
          type: value?.mimeType,
        })
      : null
  );
  const fileType = file?.type.split("/").splice(1).toString();
  const invalidType = !accept.includes(fileType!);
  const sizeInBytes = maxSize * 1000000;
  const isFileSizeAllowed = file && file.size < sizeInBytes;
  const { type } = uploadFileProps;

  const [resolutionIsValid, setResolutionIsValid] = useState(true);

  const {
    fileId,
    progress,
    uploadFile,
    cancelRequest,
    imagePreview,
    success,
    error,
    loading,
  } = useUploadFile({ axios, fileRoute });

  useEffect(() => {
    if (loading && onChange) {
      onChange(undefined);
    }
    if (!loading) {
      if (onChange && file && fileId) {
        onChange({
          id: fileId,
          isPublic: type === "Public",
          fileName: file.name,
          filePath: file.webkitRelativePath,
          cdnDomain: "",
          mimeType: file.type,
          fileSize: file.size,
        });
      }
    }
  }, [onChange, file, fileId, type, loading]);

  const resolutionValidate = useCallback(() => {
    if (resolution && file) {
      const objectUrl = URL.createObjectURL(file);
      const img = new Image();

      img.src = objectUrl;
      img.onload = () => {
        const imgWidth = img.width;
        const imgHeight = img.height;

        if (imgWidth === resolution.width && imgHeight === resolution.height) {
          setResolutionIsValid(true);
        } else {
          setFile(null);
          cancelRequest();
          setResolutionIsValid(false);
        }

        URL.revokeObjectURL(objectUrl);
      };
    }
  }, [file, cancelRequest, resolution]);

  const updloadFileFunction = useCallback(
    (file: File) => {
      const fileType = file.type.split("/").splice(1).toString();
      if (file.size < sizeInBytes && accept.includes(fileType)) {
        uploadFile({ ...uploadFileProps, file });
      }
    },
    [sizeInBytes, uploadFile, uploadFileProps, accept]
  );

  const onChangeHandler = (file: File | null) => {
    if (onFileSelected && oldValue) {
      onFileSelected(oldValue);
    }
    if (!file) {
      setFile(null);
      if (onChange) {
        onChange(undefined);
      }
      return;
    }
    if (file) {
      setResolutionIsValid(true);
      setFile(file);
      updloadFileFunction(file);
    }
  };

  useEffect(() => {
    resolutionValidate();
  }, [resolutionValidate]);

  const onUploadHandler = (file: File) => {
    updloadFileFunction(file);
  };

  return (
    <Stack>
      <Stack flexDirection={"row"} alignItems={"center"} gap={1}>
        <MuiFileInput
          value={value && value.id ? file : null}
          onChange={onChangeHandler}
          clearIconButtonProps={{
            title: "Remover arquivo",
            children: <CloseRoundedIcon fontSize="small" />,
          }}
          label={label}
          size={size}
          fullWidth
          disabled={disable}
          inputProps={{
            accept: accept,
          }}
          sx={(t) =>
            disable
              ? {
                  "& .css-ttc0ow-MuiInputBase-root-MuiOutlinedInput-root.Mui-disabled":
                    {
                      WebkitTextFillColor: t.palette.text.primary,
                    },
                }
              : {
                  backgroundColor: `${t.palette.background.default}`,
                  backgroundImage: `-webkit-linear-gradient(left, ${
                    t.palette.mode === "light"
                      ? t.palette.primary.light
                      : t.palette.primary.dark
                  } ${progress ? (progress === 100 ? 99 : progress) : 0}%, ${
                    t.palette.background.default
                  } ${progress ? (progress === 100 ? 99 : progress) : 0}%)`,
                  borderRadius: 1,
                }
          }
        />
        {progress && progress >= 1 ? (
          <Typography variant="caption" color="text.secondary">{`${
            progress === 100 ? 99 : progress
          }%`}</Typography>
        ) : undefined}
        {!progress && value && (
          <InputFileStatus
            file={file}
            value={value}
            imagePreview={imagePreview || ""}
            success={success}
            error={error}
            onUpload={onUploadHandler}
          />
        )}
      </Stack>
      {information && <InputFileInformations message={information} />}
      {file && (
        <InputFileValidations
          maxFileSize={maxSize}
          invalidType={invalidType}
          fileSizeAllowed={isFileSizeAllowed}
        />
      )}
      {!resolutionIsValid && resolution && (
        <InputFileResolutionMessage
          width={resolution.width}
          height={resolution.height}
        />
      )}
    </Stack>
  );
};
