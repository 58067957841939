import { Stack, Typography } from "@mui/material";
import { IInputFileResolutionMessageProps } from "./models";

const InputFileResolutionMessage = ({
  height,
  width,
}: IInputFileResolutionMessageProps) => {
  return (
    <Stack display={"flex"} flexDirection={"row"} gap={2}>
      <Typography color="error" fontSize={14} mt={1}>
        {`Resolução de imagem inválida, permitido apenas imagens com resolução ${width}x${height}`}
      </Typography>
    </Stack>
  );
};

export default InputFileResolutionMessage;
