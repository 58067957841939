import { Button, Grid } from "@mui/material";
import { TListSelectButtons } from "./models";

export const ListSelectButtons = ({
  handleCheckedAvailable,
  handleCheckedAdded,
  availableChecked,
  addedChecked,
}: TListSelectButtons) => {
  return (
    <Grid item>
      <Grid container direction="column" alignItems="center">
        <Button
          sx={{ my: 0.5 }}
          variant="outlined"
          size="small"
          onClick={handleCheckedAdded}
          disabled={availableChecked === 0}
          aria-label="move selected right"
        >
          Adicionar
        </Button>
        <Button
          sx={{ my: 0.5 }}
          variant="outlined"
          size="small"
          onClick={handleCheckedAvailable}
          disabled={addedChecked === 0}
          aria-label="move selected left"
        >
          Remover
        </Button>
      </Grid>
    </Grid>
  );
};
