import { Grid, Typography } from "@mui/material";
import { FormContainer } from "../../../../components/UI/Form/FormContainer";
import { FormInput } from "../../../../components/UI/Form/FormTextInput";
import { SwitchInput } from "../../../../components/UI/Form/Switch/Switch";
import { FormTransferList } from "../../../../components/UI/Form/TransferList/ControllerMultiSelect";
import { TUserProfileForm, userProfileSchema } from "./UserProfileSchema";
import PrivilegesService from "../../../../services/privileges.service";
import UserProfilesService from "../../../../services/userProfiles.service";
import { useFnRequest } from "../../../../hooks/useFnRequest/useFnRequest";

const userProfilesService = new UserProfilesService();

const privilegesService = new PrivilegesService();

const UserProfile = () => {
  const { sendRequest: save, loading: sLoading } = useFnRequest(
    userProfilesService.create
  );

  const { sendRequest: update, loading: uLoading } = useFnRequest(
    userProfilesService.updateItemById
  );

  const { sendRequest: get, loading: gLoading } = useFnRequest(
    userProfilesService.getItemById
  );

  const saveHandler = (v: TUserProfileForm) => {
    return save({
      ...v,
      privilegeIds: v.privileges.map((privilege) => privilege.id),
    });
  };

  const updateHandler = (v: TUserProfileForm, id: string) => {
    return update({
      item: {
        ...v,
        privilegeIds: v.privileges.map((privilege) => privilege.id),
      },
      id,
    });
  };

  const loading = sLoading || uLoading || gLoading;

  return (
    <FormContainer<TUserProfileForm>
      title={"Privilégio de usuário"}
      saveFunction={saveHandler}
      updateFunction={updateHandler}
      getFunction={get}
      loading={loading}
      schema={userProfileSchema}
      subtitle={"Novo privilégio de usuário"}
      subtitleWatchField={"name"}
      fields={() => (
        <Grid container spacing={2}>
          <Grid item sm={12} textAlign={"end"}>
            <SwitchInput label="Habilitar" name="enable" />
          </Grid>

          <Grid item xs={12} sm={12} mb={1}>
            <FormInput
              name="name"
              label="Nome"
              autoFocus
              size="small"
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={12} mb={1}>
            <FormInput
              name="description"
              label="Descrição"
              size="small"
              multiline
              minRows={4}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <Typography variant="h6" mb={1}>
              Privilégios
            </Typography>
            <FormTransferList
              request={privilegesService.list}
              name="privileges"
            />
          </Grid>
        </Grid>
      )}
    />
  );
};

export default UserProfile;
