import React from "react";
import { Box, CircularProgress, SxProps, Theme } from "@mui/material";

interface ILoadingProps {
  size?: number;
  sx?: SxProps<Theme>;
}

export const Loading: React.FC<ILoadingProps> = ({ sx, size = 100 }) => {
  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        zIndex: 100,
        position: "absolute",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        top: 0,
        left: 0,
        ...sx,
      }}
    >
      <CircularProgress size={size} />
    </Box>
  );
};
