import { Box, IconButton, Popover, Stack, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { IRowActions } from "./models";

import EditRoundedIcon from "@mui/icons-material/EditRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";
import DescriptionRoundedIcon from "@mui/icons-material/DescriptionRounded";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import { useRole } from "../../../../hooks/useRole";
import { useState } from "react";
import { Button } from "../../Button";

const RowActions = <T extends object>({
  params,
  rowActions,
  rowStaticActions,
  showDelete = true,
  showRegisterInfos = true,
  handleDelete,
}: IRowActions<T>) => {
  const navigate = useNavigate();
  const readPermission = useRole(["Read"]);
  const updatePermission = useRole(["Update"]);
  const deletePermission = useRole(["Delete"]);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = () => navigate(`${params.id}/editar`);

  const handleView = () => navigate(`${params.id}/visualizar`);

  const lastModified = `${
    "last_modified" in params.row ? params.row.last_modified : ""
  }`;

  const modifiedBy = `${
    "last_modified_by_user_name" in params.row
      ? params.row.last_modified_by_user_name
      : ""
  }`;

  const created = `${"created" in params.row ? params.row.created : ""}`;

  const createdBy = `${
    "created_by_user_name" in params.row ? params.row.created_by_user_name : ""
  }`;

  const handleOpenPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const hasRegisterInfos =
    lastModified.length !== 0 &&
    modifiedBy.length !== 0 &&
    created.length !== 0 &&
    createdBy.length !== 0;

  return (
    <Stack display={"flex"} flexDirection={"row"}>
      {showRegisterInfos && hasRegisterInfos && (
        <Tooltip
          title={`Registro alterado em ${format(new Date(lastModified), "Pp", {
            locale: ptBR,
          }).replaceAll(",", "")} por ${modifiedBy}. Criado em ${format(
            new Date(created),
            "Pp",
            {
              locale: ptBR,
            }
          ).replaceAll(",", "")} por ${createdBy}`}
        >
          <IconButton color="primary" size="small">
            <DescriptionRoundedIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      )}
      {readPermission && (
        <Tooltip title="Visualizar">
          <IconButton color="primary" onClick={handleView} size="small">
            <VisibilityRoundedIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      )}
      {updatePermission && (
        <Tooltip title="Editar">
          <IconButton color="primary" onClick={handleClick} size="small">
            <EditRoundedIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      )}
      {showDelete && deletePermission && (
        <Tooltip title="Excluir">
          <IconButton
            color="primary"
            onClick={() => handleDelete(params)}
            size="small"
          >
            <DeleteRoundedIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      )}
      {rowActions.length !== 0 && (
        <>
          <IconButton size="small" color="primary" onClick={handleOpenPopover}>
            <MoreHorizIcon />
          </IconButton>
          <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
          >
            <Box p={1} display={"flex"} flexDirection={"column"}>
              {rowActions.map((action) => (
                <Button
                  onClick={() => action.onClick(params)}
                  size="small"
                  startIcon={action.icon}
                  color="inherit"
                  key={action.text}
                >
                  {action.text}
                </Button>
              ))}
            </Box>
          </Popover>
        </>
      )}
      {rowStaticActions.length !== 0 &&
        rowStaticActions.map((action) => (
          <Tooltip title={action.text} key={action.text}>
            <IconButton
              color="primary"
              onClick={() => action.onClick(params)}
              size="small"
            >
              {action.icon}
            </IconButton>
          </Tooltip>
        ))}
    </Stack>
  );
};

export default RowActions;
