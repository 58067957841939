import { Table } from "../../../components/UI/Table";
import { GridColDef } from "@mui/x-data-grid";
import { MainApiService } from "../../../services/mainApi";
import { IGetUsers } from "../../../models/users";
import { Chip } from "@mui/material";
const USERS_ROUTE = process.env.REACT_APP_API + "/api/user";
const usersService = new MainApiService<IGetUsers>(USERS_ROUTE);

const UsersPage = () => {
  const columns: GridColDef<IGetUsers>[] = [
    {
      field: "name",
      headerName: "Nome",
      flex: 1,
      renderCell: (params) => {
        return params.value + ` (${params.row.code ? params.row.code : "-"})`;
      },
    },
    {
      field: "phone",
      headerName: "Phone",
      flex: 1,
      maxWidth: 180,
    },
    {
      field: "mail",
      headerName: "E-mail",
      flex: 1,
    },
    {
      field: "enable",
      headerName: "Status",
      flex: 1,
      maxWidth: 150,
      renderCell: (params) => {
        return (
          <Chip
            size="small"
            label={params.value ? "Ativo" : "Inativo"}
            color={params.value ? "success" : "error"}
          />
        );
      },
    },
  ];

  return (
    <>
      <Table<IGetUsers>
        service={usersService}
        showDefaultMacroFilters={false}
        columns={columns}
        title={"Usuários"}
        searchInput
        searchInputPlaceHolder={"Pesquisar"}
        showButton={false}
        defaultActions={false}
      />
    </>
  );
};

export default UsersPage;
