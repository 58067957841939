import { ISelectType } from "../models/requests";
import { GlobalAxios } from "../axios/axios-config";

const PRIVILEGES_ROUTE = process.env.REACT_APP_API + "/api/privilege";

export class PrivilegesService {
  list = (params?: any) => {
    return GlobalAxios.get<ISelectType[]>(PRIVILEGES_ROUTE);
  };
}

export default PrivilegesService;
