import {
  Box,
  IconButton,
  Paper,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  DataGrid,
  GridColumnVisibilityModel,
  GridRowParams,
  GridRowsProp,
  useGridApiRef,
  ptBR,
} from "@mui/x-data-grid";
import IosShareIcon from "@mui/icons-material/IosShare";
import { useCallback, useEffect, useRef, useState } from "react";
import { useConfirm } from "material-ui-confirm";
import { AnimatePresence } from "framer-motion";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import AppRegistrationRoundedIcon from "@mui/icons-material/AppRegistrationRounded";
import { useLocation, useNavigate } from "react-router-dom";

import { ITable, pageState } from "./models";

import MacroFilters from "./MacroFilters/MacroFIlters";
import ColumnsDrawer from "./ColumnsDrawer/ColumnsDrawer";
import RowActions from "./RowActions/RowActions";
import { TableActions } from "./TableActions";
import CustomPagination from "./CustomPagination/CustomPagination";
import { NoRowsMessage } from "./NoRowsMessage";
import ParamsFilter from "./paramsFilter";
import { Modal } from "../Modal";
import Filters from "./Filters/Filters";
import { SearchInput } from "./Filters/SearchInput/SearchInputs";
import { useDraggable } from "react-use-draggable-scroll";
import { UseEstablishmentContext } from "../../../contexts/Establishment-Context/establishment-context";
import { useRole } from "../../../hooks/useRole";
import { useFnRequest } from "../../../hooks/useFnRequest/useFnRequest";

// const MIN_TERM_LENGTH = 3;
const DEFAULT_PAGE_SIZE_OPTS = [5, 10, 20, 50];
const DEFAULT_PAGE_ROWS = 20;

export const Table = <T extends object>({
  columns,
  rows,
  title,
  searchInputPlaceHolder,
  defaultPageSize,
  pageSizeOptions,
  rowActions,
  onAdd,
  service,
  onRowClick,
  addButtonLabel,
  tableActionsMenu,
  confirmOptions,
  macroFilters,
  defaultActions = true,
  showButton = true,
  showDeleteButton = true,
  rowHeight = undefined,
  getRowHeight,
  showDefaultMacroFilters = true,
  syncing,
  showBreadcrumb = false,
  handleDeleteItem,
  onFetchData,
  serviceProps,
  backButton = false,
  textFilters,
  showRegisterInfos = true,
  minSearchLength = 3,
  initialSortModel,
  rowStaticActions,
  rowActionsMaxWidth = 130,
  showMoreOptions = false,
  canExportCsv = false,
  filters,
}: ITable<T>) => {
  const [data, setData] = useState<GridRowsProp<T>>(
    !service && rows ? rows : []
  );
  const apiRef = useGridApiRef();
  const { loading, sendRequest } = useFnRequest(service?.getAll!);
  const [pageInfo, setPageInfo] = useState<pageState>();
  const [searchBy, setSearchBy] = useState<string>();
  const deleteRequest = useFnRequest(service?.deleteItemById!);
  const confirm = useConfirm();
  const location = useLocation();
  const [filterParams, setFilterParams] = useState<string[] | null>(null);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const ref =
    useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>;
  const { events } = useDraggable(ref);
  const { setUpdateEstablishment } = UseEstablishmentContext();

  const [filtersProps, setFiltersProps] = useState<
    Record<string, any> | undefined
  >();

  const applyFilters = useCallback(() => {
    filters?.map((filter) => {
      const localData = localStorage.getItem(
        `${filter.key}.${window.location.pathname}`
      );

      try {
        const value = JSON.parse(localData || "");

        if (value) {
          if (value.description === "id") {
            const isNumber = value.id === "1" || value.id === "2";

            if (isNumber) {
              return setFiltersProps((oldState) => ({
                ...oldState,
                [filter.key]: value.id === "1" ? true : "false",
              }));
            }

            setFiltersProps((oldState) => ({
              ...oldState,
              [filter.key]: value.id,
            }));
          }
          if (value.description === "name") {
            setFiltersProps((oldState) => ({
              ...oldState,
              [filter.key]: value.name,
            }));
          }
          if (value.description === "code") {
            if (value.code) {
              setFiltersProps((oldState) => ({
                ...oldState,
                [filter.key]: value.code,
              }));
            }
          }
        }
      } catch (error) {
        return setFiltersProps((oldState) => ({
          ...oldState,
          [filter.key]: localData,
        }));
      }

      return filter;
    });

    localStorage.setItem(window.location.pathname, "true");
  }, [filters]);

  useEffect(() => {
    const canFilter = !!localStorage.getItem(window.location.pathname);

    if (canFilter) {
      applyFilters();
    }
  }, [applyFilters]);

  const cleanFilters = () => {
    filters?.map((filter) =>
      localStorage.removeItem(`${filter.key}.${location.pathname}`)
    );
    setFiltersProps(undefined);
    localStorage.removeItem(window.location.pathname);
  };

  const readPermission = useRole(["Read"]);
  const createPermission = useRole(["Create"]);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const onCloseHandler = () => setOpen(false);

  const loadFilterIds = useCallback(() => {
    if (location.search) {
      setFilterParams(location.search.substring(8).split(";"));
    }
  }, [location.search]);

  useEffect(() => {
    loadFilterIds();
  }, [loadFilterIds]);

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: defaultPageSize || DEFAULT_PAGE_ROWS,
  });

  const [rowCountState, setRowCountState] = useState<number>(
    pageInfo?.totalRowCount || 0
  );

  const handleFilterDelete = (item: string) => {
    const itemRemoved = filterParams?.filter((param) => param !== item);
    if (itemRemoved) {
      setFilterParams(itemRemoved);
    }
    if (itemRemoved?.length === 0) {
      setFilterParams(null);
    }
  };

  const cleanFilter = () => {
    setOpen(false);
    setFilterParams(null);
  };

  const handleCsv = () => {
    apiRef.current.exportDataAsCsv({
      fileName: title,
    });
  };

  const loadRequest = useCallback(async () => {
    const { data, success } = await sendRequest({
      limit: paginationModel.pageSize,
      page: paginationModel.page + 1,
      searchBy: searchBy,
      FilterIds: filterParams ? filterParams : undefined,
      ...filtersProps,
      ...serviceProps,
    });

    if (onFetchData && success) {
      onFetchData(data.data);
    }

    if (success) {
      setData(data.data);
      setRowCountState(data.total || 0);
      setPageInfo({
        totalRowCount: data.total || 0,
      });
    }
  }, [
    filterParams,
    onFetchData,
    paginationModel,
    searchBy,
    sendRequest,
    serviceProps,
    filtersProps,
  ]);

  useEffect(() => {
    if (readPermission) {
      loadRequest();
    }
  }, [loadRequest, readPermission]);

  const handleDelete = (params: GridRowParams<T>) => {
    confirm({
      ...confirmOptions,
      cancellationText: "Cancelar",
      confirmationText: "Sim, deletar",
    })
      .then(async () => {
        const id = params.id.toString();
        if (handleDeleteItem) {
          const data = await handleDeleteItem(id);
          if (data) {
            apiRef.current.updateRows([{ id: id, _action: "delete" }]);
          }
        }
        const data = await deleteRequest.sendRequest(id);
        if (data) {
          apiRef.current.updateRows([{ id: id, _action: "delete" }]);
          loadRequest();
        }
        if (window.location.href.includes("estabelecimentos")) {
          setUpdateEstablishment((prevState) => (prevState = prevState + 1));
        }
      })
      .catch(() => {
        /* ... */
      });
  };

  const defaultActionsArray = [
    {
      field: "Ações",
      type: "actions",
      minWidth: 130,
      flex: 1,
      maxWidth: rowActions ? 200 : rowActionsMaxWidth,
      getActions: (params: GridRowParams<T>) => {
        return [
          <RowActions
            showRegisterInfos={showRegisterInfos}
            params={params}
            handleDelete={handleDelete}
            rowActions={rowActions || []}
            rowStaticActions={rowStaticActions || []}
            showDelete={showDeleteButton}
          />,
        ];
      },
    },
  ];

  const realColumns = [
    ...(defaultActions ? defaultActionsArray : []),
    ...columns.map((col) => ({
      ...col,
      flex: 1 / (columns.length - 1),
      minWidth: col.minWidth ? col.minWidth : 100,
    })),
  ];

  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      pageInfo?.totalRowCount !== undefined
        ? pageInfo?.totalRowCount
        : prevRowCountState
    );
  }, [pageInfo?.totalRowCount, setRowCountState]);

  const [columnVisibilityModel, setColumnVisibilityModel] =
    useState<GridColumnVisibilityModel>(
      realColumns.reduce((a, v) => ({ ...a, [v.field]: true }), {})
    );

  return (
    <Stack sx={{ position: "relative", height: "100%", overflow: "hidden" }}>
      <Stack
        direction={"row"}
        justifyContent={
          showBreadcrumb || backButton ? "space-between" : "flex-end"
        }
        mb={3}
      >
        {backButton && (
          <Tooltip title={"Voltar"}>
            <IconButton
              size="small"
              aria-label="back"
              onClick={() => navigate(-1)}
              sx={{ padding: 0 }}
            >
              <ArrowBackIosNewRoundedIcon />
            </IconButton>
          </Tooltip>
        )}
        {/* {showBreadcrumb && breadcrumbRoutes && (
          <BreadCrumb paths={breadcrumbRoutes} />
        )} */}
      </Stack>

      <Stack
        mb={matches ? 2 : 0}
        width={"100%"}
        display={"flex"}
        flexDirection={matches ? "column" : "row"}
        alignItems={matches ? "start" : "center"}
        justifyContent={"space-between"}
      >
        <Typography variant="h5" mb={2}>
          {title}
        </Typography>
        <Box
          height={"36px"}
          width={matches ? "100%" : undefined}
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"end"}
        >
          <AnimatePresence>
            <TableActions
              onAddHandler={onAdd}
              addButtonLabel={addButtonLabel}
              showAddButton={showButton && createPermission}
              showMoreOptions={showMoreOptions}
              actionMenuItems={
                canExportCsv
                  ? [
                      {
                        text: "Exportar CSV",
                        icon: <IosShareIcon fontSize="small" />,
                        action: handleCsv,
                        loading: false,
                      },
                      ...(tableActionsMenu || []),
                    ]
                  : tableActionsMenu || []
              }
            />
          </AnimatePresence>
        </Box>
      </Stack>

      <Stack
        width={"100%"}
        minWidth={"200px"}
        display={"flex"}
        flexDirection={"row"}
        alignItems={"center"}
        flexWrap={"wrap"}
        ref={ref}
        {...events}
      >
        <Box
          sx={{
            py: 2,
            width: "100%",
            minWidth: "200px",
            height: "fit-content",
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "space-between",
            gap: 1,
          }}
        >
          <Stack flexDirection={"row"} flexWrap={"wrap"} gap={1}>
            {searchInputPlaceHolder && (
              <SearchInput
                searchBy={searchBy}
                minLength={minSearchLength}
                onSearch={(term: string) => {
                  if (term?.length >= minSearchLength) {
                    setSearchBy(term);
                  } else {
                    setSearchBy("");
                  }
                }}
                maxWidth={!matches ? 380 : undefined}
                minWidth={matches ? 360 : undefined}
                label={searchInputPlaceHolder || ""}
              />
            )}

            <MacroFilters
              ShowDefault={showDefaultMacroFilters}
              api={apiRef}
              filters={macroFilters}
            />
          </Stack>

          <Stack flexDirection={"row"}>
            {filters && (
              <Filters
                filters={filters || []}
                textFilters={textFilters}
                applyFilters={applyFilters}
                cleanFilters={cleanFilters}
                serviceProps={filtersProps}
              />
            )}
            <ColumnsDrawer
              columns={realColumns}
              columnVisibilityModel={columnVisibilityModel}
              VisibilityChanges={(v) => setColumnVisibilityModel(v)}
            />
          </Stack>
        </Box>

        <Modal open={open} onClose={onCloseHandler}>
          {filterParams && (
            <ParamsFilter
              filterItems={filterParams}
              onDelete={handleFilterDelete}
              cleanFilter={cleanFilter}
            />
          )}
        </Modal>

        {matches && filterParams && (
          <IconButton
            onClick={() => {
              setOpen(true);
            }}
          >
            <AppRegistrationRoundedIcon fontSize="medium" />
          </IconButton>
        )}

        {!matches && filterParams && (
          <ParamsFilter
            filterItems={filterParams}
            onDelete={handleFilterDelete}
            cleanFilter={cleanFilter}
          />
        )}
      </Stack>

      <Paper
        sx={{
          overflow: "hidden",
          width: "100%",
          height: "100%",
        }}
        component={Box}
        elevation={0}
      >
        {data && (
          <DataGrid
            sx={(theme) => ({
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor:
                  theme.palette.mode === "dark"
                    ? theme.palette.grey[900]
                    : theme.palette.grey[200],
              },
            })}
            rowHeight={rowHeight ? rowHeight : undefined}
            checkboxSelection={false}
            getRowHeight={getRowHeight}
            apiRef={apiRef}
            disableColumnFilter
            rows={data}
            disableColumnMenu
            loading={loading || syncing}
            columns={realColumns}
            columnVisibilityModel={columnVisibilityModel}
            paginationModel={paginationModel}
            rowCount={rowCountState}
            hideFooterSelectedRowCount
            onRowClick={onRowClick}
            paginationMode="server"
            onPaginationModelChange={setPaginationModel}
            pageSizeOptions={pageSizeOptions || DEFAULT_PAGE_SIZE_OPTS}
            localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
            slots={{
              pagination: CustomPagination,
              noRowsOverlay: NoRowsMessage,
            }}
            initialState={{
              sorting: {
                sortModel: initialSortModel,
              },
            }}
          />
        )}
      </Paper>
    </Stack>
  );
};
