import { Grid } from "@mui/material";
import { AutoComplete } from "../../../../../components/UI/Form/Autocomplete";
import { useCallback, useEffect, useState } from "react";
import { ISelectType } from "../../../../../contexts/Config-Context";
import { useFormContext, useWatch } from "react-hook-form";
import { TEstabilishmentForm } from "../EstabilishmentSchema";

interface IEstablishmentMediaTypeProps {
  index: number;
  data: ISelectType[];
  onHasOptions: (value: boolean) => void;
  loadDefaultMedia: (media: ISelectType) => void;
}

const EstablishmentMediaType = ({
  index,
  data,
  onHasOptions,
  loadDefaultMedia,
}: IEstablishmentMediaTypeProps) => {
  const { control } = useFormContext<TEstabilishmentForm>();

  const [mediaTypeOptions, setMediaTypeOptions] = useState<ISelectType[]>([]);

  const selectedMedia = useWatch({
    name: "establishmentMediaFiles",
    control,
  });

  const filterMediaTypeOptions = useCallback(() => {
    if (!selectedMedia) return;

    const selectedMediaType = selectedMedia.find(
      (lang, indexLang) => indexLang === index
    );
    const filteredOptions = data.filter(
      (objeto2) =>
        !selectedMedia.some((objeto1) => objeto1.mediaType?.id === objeto2.id)
    );

    loadDefaultMedia(filteredOptions[0]);

    if (selectedMediaType?.mediaType && filteredOptions.length !== 0) {
      setMediaTypeOptions([
        ...filteredOptions,
        {
          id: selectedMediaType.mediaType.id,
          name: selectedMediaType.mediaType.name,
        },
      ]);
    } else {
      setMediaTypeOptions(filteredOptions);
    }

    if (filteredOptions.length === 0) {
      onHasOptions(false);
    } else {
      onHasOptions(true);
    }
  }, [data, index, selectedMedia, onHasOptions, loadDefaultMedia]);

  useEffect(() => {
    filterMediaTypeOptions();
  }, [filterMediaTypeOptions]);

  return (
    <Grid item xs={12} sm={3} mb={1}>
      <AutoComplete
        name={`establishmentMediaFiles.${index}.mediaType`}
        label={"Tipo de mídia"}
        getOptionLabel={(option) => {
          return option.name;
        }}
        size="small"
        options={
          mediaTypeOptions.sort((a, b) =>
            a.code && b.code
              ? a.code < b.code
                ? -1
                : a.code > b.code
                ? 1
                : 0
              : 0
          ) || []
        }
        canClear={false}
      />
    </Grid>
  );
};

export default EstablishmentMediaType;
