import { useFieldArray, useFormContext } from "react-hook-form";
import { FormArray } from "../../../../../components/UI/Form/FormArray";
import GenericService from "../../../../../services/generics.service";
import EstablishmentFile from "./EstablishmentFile";
import { TEstabilishmentForm } from "../EstabilishmentSchema";
import EstablishmentMediaType from "./EstablishmentMediaType";
import { useCallback, useEffect, useState } from "react";
import { ISelectType } from "../../../../../models/requests";
import { IInputFile } from "../../../../../components/UI/InputFile";
import { useFnRequest } from "../../../../../hooks/useFnRequest/useFnRequest";

const MEDIA_TYPE_ROUTE = process.env.REACT_APP_API + "/api/generic";
const mediaTypeService = new GenericService(MEDIA_TYPE_ROUTE);

interface IEstablishmentFilesProps {
  onDeleteFileHandler: (file: IInputFile) => void;
}

const EstablishmentFiles = ({
  onDeleteFileHandler,
}: IEstablishmentFilesProps) => {
  const { control, formState } = useFormContext<TEstabilishmentForm>();

  const [hasOptions, setHasOptions] = useState(true);

  const [mediaTypes, setMediaTypes] = useState<ISelectType[]>([]);

  const [defaultMediaType, setDefaultMediaType] = useState<ISelectType>();

  const filesFields = useFieldArray<
    TEstabilishmentForm,
    "establishmentMediaFiles",
    "key"
  >({
    control,
    name: "establishmentMediaFiles",
    keyName: "key",
  });

  const { sendRequest } = useFnRequest(mediaTypeService.list);

  const fetchLanguages = useCallback(async () => {
    const { data, success } = await sendRequest("MEDIA_TYPE_ESTABLISHMENT");
    if (data && success) {
      setMediaTypes(data);
    }
  }, [sendRequest]);

  useEffect(() => {
    fetchLanguages();
  }, [fetchLanguages]);

  const loaddefaultMediaType = useCallback((media: ISelectType) => {
    setDefaultMediaType(media);
  }, []);

  return (
    <FormArray
      title="Arquivos de mídia"
      name="establishmentMediaFiles"
      errorMessage={formState.errors?.establishmentMediaFiles?.message}
      addButtonLabel={"Adicionar arquivo"}
      showAddButton={hasOptions}
      formArray={filesFields}
      showDeleteButton={true}
      rowAlign="start"
      appendValue={{
        mediaType: defaultMediaType ? defaultMediaType : mediaTypes[0],
      }}
      fieldsObject={(index) => {
        return (
          <>
            <EstablishmentMediaType
              index={index}
              data={
                mediaTypes.sort((a, b) =>
                  a.code && b.code
                    ? a.code < b.code
                      ? -1
                      : a.code > b.code
                      ? 1
                      : 0
                    : 0
                ) || []
              }
              onHasOptions={setHasOptions}
              loadDefaultMedia={loaddefaultMediaType}
            />

            <EstablishmentFile
              index={index}
              onDeleteFileHandler={onDeleteFileHandler}
            />
          </>
        );
      }}
    />
  );
};

export default EstablishmentFiles;
