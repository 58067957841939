import { GlobalAxios } from "../axios/axios-config";
import {
  IForgotPassword,
  ILoginUser,
  IResetPassword,
  ITokenByToken,
  IUsersTokenPost,
  IUsersTokenPostResponse,
} from "../models/user";
import { tokenService } from "./token.service";
import axios from "axios";

const BASE_URL = process.env.REACT_APP_API;

const login = (user: ILoginUser) => {
  const grant_type = "password";

  const request: IUsersTokenPost = {
    password: user.password,
    username: user.username,
    grant_type,
  };

  return axios.post<IUsersTokenPostResponse>(
    BASE_URL + "/api/auth/loginAdmin",
    request
  );
};

const forgotPassword = (user: IForgotPassword) => {
  const request: IForgotPassword = {
    login: user.login,
  };

  return axios.post<IForgotPassword>(
    BASE_URL + "/api/user/forgotPassword",
    request
  );
};

const resetPassword = (user: IResetPassword) => {
  const request: IResetPassword = {
    ...user,
  };

  return axios.post<IResetPassword>(
    BASE_URL + "/api/user/resetPassword",
    request
  );
};

const refreshToken = (establishmentId?: string) => {
  const refreshToken = tokenService.getTokens().refreshToken;

  return axios.post<IUsersTokenPostResponse>(
    BASE_URL + "/api/auth/refreshToken",
    null,
    {
      headers: {
        Authorization: "Bearer " + refreshToken,
      },
    }
  );
};

const tokenByToken = ({ establishmentId, token }: ITokenByToken) => {
  return GlobalAxios.post<IUsersTokenPostResponse>(
    BASE_URL + `/api/auth/tokenByToken`,
    { establishmentId: establishmentId },
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
};

export const authService = {
  login,
  refreshToken,
  tokenByToken,
  forgotPassword,
  resetPassword,
};
