import { useCallback, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

const useRouteForNewEstablishment = () => {
  const { pathname } = useLocation();
  const params = useParams();

  const [route, setRoute] = useState("");

  const loadNewRoute = useCallback(
    (routeParams: string, splitedRoute: string[]) => {
      const index = splitedRoute.findIndex((p) => p === routeParams);
      setRoute(
        "/" + splitedRoute.slice(3, index).toString().replaceAll(",", "/")
      );
    },
    []
  );

  useEffect(() => {
    const splitedRoute = pathname.split("/");

    if (params.id) loadNewRoute(params.id, splitedRoute);

    if (Object.keys(params).length === 1) {
      setRoute("/" + splitedRoute.splice(3).toString().replaceAll(",", "/"));
    }
  }, [pathname, params, loadNewRoute]);

  return route;
};

export default useRouteForNewEstablishment;
