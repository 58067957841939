import React, { memo } from "react";

import {
  Autocomplete,
  Stack,
  TextField,
  Avatar as MuiAvatar,
  Typography,
  Box,
  Tooltip,
  useTheme,
  useMediaQuery,
} from "@mui/material";

import { IEstablishmentModalProps } from "./models";
import { Modal } from "../../UI/Modal";
import { getInitials, stringToColor } from "../../../utils";
import { Button } from "../../UI/Button";

const EstablishmentModal: React.FC<IEstablishmentModalProps> = ({
  onChange,
  value,
  onClick,
  loading,
  onClose,
  open,
  establishments,
  actualEstablishment,
}) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={"Estabelecimento"}
      minWidth={matches ? 280 : 320}
    >
      <Autocomplete
        fullWidth
        onChange={(event: any, newValue) => {
          onChange(newValue);
        }}
        isOptionEqualToValue={(option, value) => {
          return option.id === value.id;
        }}
        disableClearable
        value={value}
        options={establishments}
        autoHighlight
        getOptionLabel={(option) => option.name}
        renderOption={(props, option) => (
          <Box component="li" {...{ ...props, key: option.id }}>
            <Stack
              padding={2}
              spacing={2}
              direction={"row"}
              alignItems={"center"}
            >
              <MuiAvatar
                sx={(theme) => ({
                  bgcolor: stringToColor(option.id),
                  width: 30,
                  height: 30,
                  color: theme.palette.getContrastText(
                    stringToColor(option.id)
                  ),
                })}
              >
                {getInitials(option.name)}
              </MuiAvatar>

              <Typography>{option.name}</Typography>
            </Stack>
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            size="small"
            inputProps={{
              ...params.inputProps,
            }}
          />
        )}
      />
      <Tooltip
        placement="top"
        title={establishments.length === 1 ? "Tooltip" : ""}
      >
        <Stack>
          <Button
            loading={loading}
            variant="contained"
            disabled={
              establishments.length <= 1 || value.id === actualEstablishment?.id
                ? true
                : false
            }
            onClick={onClick}
          >
            Alterar
          </Button>
        </Stack>
      </Tooltip>
    </Modal>
  );
};

export default memo(EstablishmentModal);
