import { Grid } from "@mui/material";
import { FormContainer } from "../../../../components/UI/Form/FormContainer";
import { FormInput } from "../../../../components/UI/Form/FormTextInput";
import { MainApiService } from "../../../../services/mainApi";
import {
  TEstabilishmentForm,
  estabilishmentSchema,
} from "./EstabilishmentSchema";
import { SwitchInput } from "../../../../components/UI/Form/Switch/Switch";
import { ISelectType } from "../../../../contexts/Config-Context";
import { AutoCompleteMulti } from "../../../../components/UI/Form/AutocompleteMulti";
import EstablishmentsService from "../../../../services/establishments.service";
import { useFnRequest } from "../../../../hooks/useFnRequest/useFnRequest";
import EstablishmentFiles from "./EstablishmentFiles/EstablishmentFiles";
import { useCallback, useState } from "react";
import { Treturn } from "../../../../hooks/useFetch";
import { IInputFile } from "../../../../components/UI/InputFile";
import EstablishmentMails from "./EstablishmentMail/EstablishmentMails";

const estabilishmentService = new EstablishmentsService();

const USERS_ROUTE = process.env.REACT_APP_API + "/api/user/";
const userService = new MainApiService<ISelectType>(USERS_ROUTE);

const Estabilishment = () => {
  const [establishmentMediaFilesId, setEstablishmentMediaFilesId] = useState<
    string[]
  >([]);

  let deleteFilesList: string[] = [];

  const onDeleFileHandler = (file: IInputFile) => {
    const selectedFile = file;
    if (selectedFile.id.length !== 0) {
      deleteFilesList.push(selectedFile.id);
    }
  };

  const { sendRequest: save, loading: sLoading } = useFnRequest(
    estabilishmentService.create
  );

  const { sendRequest: update, loading: uLoading } = useFnRequest(
    estabilishmentService.updateItemById
  );

  const { sendRequest: get, loading: gLoading } = useFnRequest(
    estabilishmentService.getItemById
  );

  const saveHandler = (v: TEstabilishmentForm) => {
    return save({
      ...v,
      siteName: v.siteName,
      document: v.document
        .replaceAll(".", "")
        .replaceAll("/", "")
        .replaceAll("-", ""),
      userIds: v.involvedUsers
        ? v.involvedUsers.map((user) => user.id)
        : [],
      addMediaFiles: v.establishmentMediaFiles
        ? v.establishmentMediaFiles.map((media) => media.file?.id || "")
        : undefined,
      slogan: v.slogan,
      deleteMediaFiles: deleteFilesList
    });
  };

  const updateHandler = (v: TEstabilishmentForm, id: string) => {
    const updatedFiles = v.establishmentMediaFiles?.filter(
      (media) => !establishmentMediaFilesId.includes(media.file?.id || "")
    );

    return update({
      item: {
        ...v,
        siteName: v.siteName,
        document: v.document
          .replaceAll(".", "")
          .replaceAll("/", "")
          .replaceAll("-", ""),
        userIds: v.involvedUsers
          ? v.involvedUsers.map((user) => user.id)
          : [],
        addMediaFiles: updatedFiles?.map((media) =>
          media.file ? media.file.id! : ""
        ),
        slogan: v.slogan,
        deleteMediaFiles: deleteFilesList,
      },
      id: id,
    });
  };

  const getHandler = useCallback(
    async (id: string): Promise<Treturn<TEstabilishmentForm>> => {
      const { data, success } = await get(id);
      if (data) {
        const newData: TEstabilishmentForm = {
          ...data,
          establishmentMediaFiles: data.establishmentMediaFiles.map(
            (media) => ({
              mediaType: media.mediaType,
              file: { ...media, fileSize: Number(media.fileSize) },
            })
          ),
        };

        const filesId = newData.establishmentMediaFiles?.map(
          (media) => media.file?.id || ""
        );
        if (filesId) setEstablishmentMediaFilesId(filesId);

        return {
          data: newData,
          success: true,
        };
      }

      return { data, success };
    },
    [get]
  );

  const loading = sLoading || uLoading || gLoading;

  return (
    <FormContainer<TEstabilishmentForm>
      title={"Estabelecimento"}
      saveFunction={saveHandler}
      updateFunction={updateHandler}
      getFunction={getHandler}
      loading={loading}
      schema={estabilishmentSchema}
      subtitle={"Novo estabelecimento"}
      subtitleWatchField={"name"}
      fields={() => (
        <Grid container spacing={2}>
          <Grid item sm={12} textAlign={"end"}>
            <SwitchInput label="Ativar" name="enable" />
          </Grid>

          <Grid item xs={12} lg={2} mb={1}>
            <FormInput
              name="document"
              label="CNPJ"
              autoFocus
              size="small"
              fullWidth
              mask={"00.000.000/0000-00"}
            />
          </Grid>

          <Grid item xs={12} lg={5} mb={1}>
            <FormInput name="name" label="Nome" size="small" fullWidth />
          </Grid>

          <Grid item xs={12} lg={5} mb={1}>
            <FormInput
              name="siteName"
              label="Nome de exibição no site"
              size="small"
              fullWidth
            />
          </Grid>

          <Grid item sm={12} mb={1}>
            <AutoCompleteMulti
              name="involvedUsers"
              label="Usuários que terão acesso"
              size="small"
              request={userService.list}
            />
          </Grid>

          <Grid item xs={12} sm={12} mb={1}>
            <FormInput name="slogan" label="Slogan" size="small" fullWidth />
          </Grid>

          <EstablishmentFiles onDeleteFileHandler={onDeleFileHandler} />

          <EstablishmentMails />
        </Grid>
      )}
    />
  );
};

export default Estabilishment;
