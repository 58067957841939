import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useCallback, useEffect, useState } from "react";
import { ptBR as portuguese } from "@mui/x-date-pickers/locales";
import dayjs from "dayjs";
import { IFilter } from "../../models";
import { useLocation } from "react-router-dom";

const DateFilter = ({ filter }: { filter: IFilter }) => {
  const { pathname } = useLocation();

  const localData = localStorage.getItem(`${filter.key}.${pathname}`);

  const [value, setValue] = useState<Date | dayjs.Dayjs | null>(
    localData ? dayjs(localData) : null
  );

  const cleanValue = useCallback(() => {
    localStorage.removeItem(`${filter.key}.${pathname}`);
    setValue(null);
  }, [filter.key, pathname]);

  const filterHandler = useCallback(() => {
    if (!value) return;

    localStorage.setItem(
      `${filter.key}.${pathname}`,
      value.toISOString().substring(0, 10)
    );
  }, [filter.key, pathname, value]);

  useEffect(() => {
    filterHandler();
  }, [filterHandler]);

  useEffect(() => {
    if (!value) {
      cleanValue();
    }
  }, [cleanValue, value]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        value={value}
        slotProps={{
          field: { clearable: true, onClear: cleanValue },
          textField: {
            size: "small",
          },
        }}
        sx={{ minWidth: 240, width: "100%" }}
        format={"DD/MM/YYYY"}
        label={filter.label}
        onChange={(value: any) => setValue(value)}
        localeText={
          portuguese.components.MuiLocalizationProvider.defaultProps.localeText
        }
      />
    </LocalizationProvider>
  );
};

export default DateFilter;
