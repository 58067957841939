import { Outlet, useLocation } from "react-router-dom";
import Header from "../components/Menu/Header/Header";
import SideMenu from "../components/Menu/SideMenu/SideMenu";
import { Box } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { motion } from "framer-motion";
import { savePermissions, userMe } from "../services/user.service";
import { IUser, UserProvider } from "../contexts/User-Context";
import Footer from "../components/UI/Footer";
import { UseEstablishmentContext } from "../contexts/Establishment-Context/establishment-context";
import { PrivilegesContextProvider } from "../contexts/Privileges-Context";
import ReactGA from "react-ga4";
import { Environment } from "../components/UI/EnvironmentBar";
import { useFnRequest } from "../hooks/useFnRequest/useFnRequest";
import { useMedias } from "../contexts/Medias-Context/medias-context";

const RootPage = () => {
  const [open, setOpen] = useState(false);

  const [user, setUser] = useState<IUser>();

  const { updateEstablishment } = UseEstablishmentContext();

  const { pathname } = useLocation();

  const { fetchMediaFiles } = useMedias();

  const closeHandler = () => setOpen(false);
  const openHandler = () => setOpen(true);

  const { sendRequest: userMeRequest } = useFnRequest(userMe);

  const getUserMe = useCallback(async () => {
    const { data, success } = await userMeRequest(null);
    if (data && success) {
      setUser(data);
      fetchMediaFiles(data.mediaFiles, data.establishmentSlogan);
      savePermissions(data.privileges);

      const favicon = data.mediaFiles.find(
        (media) => media.mediaType.code === "3"
      );
      const currentFavicon: any = document.getElementById("favicon");

      if (favicon) {
        if (currentFavicon) {
          currentFavicon.href = favicon.cdnDomain + "/" + favicon.filePath;
        }
      } else {
        if (currentFavicon) {
          currentFavicon.href = "/dimdim_favicon_500x500.png";
        }
      }
    }
  }, [userMeRequest, fetchMediaFiles]);

  useEffect(() => {
    getUserMe();
  }, [getUserMe, updateEstablishment]);

  const getGAKey = useCallback(async () => {
    const TRACKING_ID = "G-GRHSCBXCGF";
    ReactGA.initialize(TRACKING_ID);
  }, []);

  useEffect(() => {
    getGAKey();
  }, [getGAKey]);

  // Google analytcs
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: pathname,
    });
  }, [pathname]);

  return (
    <>
      {user ? (
        <PrivilegesContextProvider
          privileges={"privileges" in user ? user.privileges : []}
        >
          <UserProvider user={user}>
            <>
              {process.env.REACT_APP_ENVIRONMENT !== "production" && (
                <Environment />
              )}
              <Header
                onOpenHandler={openHandler}
                establishments={user.userEstablishments.map((e) => ({
                  id: e.id,
                  code: e.code ? e.code : null,
                  name: e.name,
                  enable: true,
                  tenantSequential: e.code ? +e.code : 0,
                }))}
              />

              <Box
                sx={{
                  minHeight: 1,
                  display: "flex",
                  flexDirection: { xs: "column", lg: "row" },
                }}
              >
                <SideMenu open={open} onCloseHandler={closeHandler} />
                <motion.main
                  style={{
                    width: "100%",
                    height: "100%",
                    overflow: "auto",
                  }}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.7, ease: [0.6, -0.05, 0.01, 0.99] }}
                  id="main"
                >
                  <Box
                    pt={10}
                    pb={10}
                    paddingInline={2}
                    sx={(theme) => ({
                      height: "100vh",
                      backgroundColor: theme.palette.background.default,
                    })}
                  >
                    <Outlet></Outlet>
                  </Box>
                </motion.main>
              </Box>
              <Footer />
            </>
          </UserProvider>
        </PrivilegesContextProvider>
      ) : (
        <></>
      )}
    </>
  );
};

export default RootPage;
