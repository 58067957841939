import { z } from "zod";

const involvedUsersSchema = z.object({
  id: z.string(),
  name: z.string(),
  code: z.string().optional().nullable(),
});

const mediaTypeSchema = z.object({
  id: z.string(),
  name: z.string(),
  code: z.string().optional().nullable(),
  order: z.number().optional().nullable(),
});

const fileSchema = z.object({
  id: z.string(),
  establishmentId: z.string().optional().nullable(),
  isPublic: z.boolean(),
  fileName: z.string().optional().nullable(),
  cdnDomain: z.string().optional().nullable(),
  filePath: z.string().optional().nullable(),
  mimeType: z.string().optional().nullable(),
  fileSize: z.number().optional().nullable(),
});

const establishmentMediaFilesSchema = z.object({
  file: fileSchema.optional().nullable(),
  mediaType: mediaTypeSchema,
});

const mailMethodsSchema = z.object({
    publicKey: z.string().optional().nullable(),
    privateKey: z.string().optional().nullable(),
    method: mediaTypeSchema,
});

export const estabilishmentSchema = z.object({
  id: z.string().optional(),
  enable: z.boolean(),
  name: z.string().min(1, "O nome é obrigatório"),
  siteName: z.string().min(1, "O nome de exibição no site é obrigatório"),
  document: z.string().min(1, "O CNPJ é obrigatório"),
  involvedUsers: z.array(involvedUsersSchema).optional(),
  slogan: z.string().optional(),
  establishmentMediaFiles: z.array(establishmentMediaFilesSchema).optional(),
  mailMethods: z.array(mailMethodsSchema).optional(),
});

export type TEstabilishmentForm = z.infer<typeof estabilishmentSchema>;
