import { GlobalAxios } from "../axios/axios-config";
import { ISelectType } from "../contexts/Config-Context";
import { IEstablishment, IGetEstablishment } from "../models/establishments";

const BASE_URL = process.env.REACT_APP_IDENTIY;
const USER_ESTABLISHMENT_ROUTE = BASE_URL + "/api/v1/Users/Me/Establishments";

const ESTABLISHMENT_ROUTE = process.env.REACT_APP_API + "/api/establishment";

export class EstablishmentsService {
  create = (item: IEstablishment) => {
    return GlobalAxios.post(ESTABLISHMENT_ROUTE, item);
  };

  getItemById = (id: string) => {
    return GlobalAxios.get<IGetEstablishment>(ESTABLISHMENT_ROUTE + "/" + id);
  };

  updateItemById = ({ item, id }: { item: IEstablishment; id: string }) => {
    return GlobalAxios.put(ESTABLISHMENT_ROUTE + "/" + id, item);
  };

  list = (params?: any) => {
    return GlobalAxios.get<ISelectType[]>(USER_ESTABLISHMENT_ROUTE, {
      params: params,
    });
  };
}

export default EstablishmentsService;
