import React from "react";
import { Stack, Typography } from "@mui/material";

const InputFileValidations = ({
  invalidType,
  fileSizeAllowed,
  maxFileSize,
}: {
  invalidType: boolean;
  fileSizeAllowed: boolean | null | undefined;
  maxFileSize: number;
}) => {
  return (
    <Stack display={"flex"} flexDirection={"row"} gap={2}>
      {invalidType && (
        <Typography color="error" fontSize={14} mt={1}>
          Tipo de arquivo não suportado
        </Typography>
      )}
      {!fileSizeAllowed && (
        <Typography color="error" fontSize={14} mt={1}>
          Tamanho de arquivo não suportado. (Max {maxFileSize} MB),
        </Typography>
      )}
    </Stack>
  );
};

export default InputFileValidations;
