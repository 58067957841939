import { Table } from "../../../components/UI/Table";
import { GridColDef } from "@mui/x-data-grid";
import { MainApiService } from "../../../services/mainApi";
import { useNavigate } from "react-router-dom";
import { Chip, Typography } from "@mui/material";
import { IContentHelps } from "../../../models/content-help";

const CONTENT_HELP_ROUTE = process.env.REACT_APP_API + "/api/helpContent";
const contentHelpService = new MainApiService<IContentHelps>(
  CONTENT_HELP_ROUTE
);

const HelpContentPage = () => {
  const navigate = useNavigate();

  const columns: GridColDef<IContentHelps>[] = [
    {
      field: "title",
      headerName: "Título",
      flex: 1,
      maxWidth: 400,
    },
    {
      field: "content",
      headerName: "Conteúdo",
      flex: 1,
      renderCell: (params) => {
        return params.value ? (
          <Typography variant="subtitle2" noWrap>
            {params.value.replace(/(<([^>]+)>)/gi, "")}
          </Typography>
        ) : (
          "-"
        );
      },
    },
    {
      field: "enable",
      headerName: "Status",
      maxWidth: 150,
      renderCell: (params) => {
        return (
          <Chip
            size="small"
            label={params.value ? "Ativo" : "Inativo"}
            color={params.value ? "success" : "error"}
          />
        );
      },
    },
  ];

  const addHanlder = () => navigate("novo");

  return (
    <Table<IContentHelps>
      service={contentHelpService}
      showDefaultMacroFilters={false}
      columns={columns}
      title={"Conteúdos de Ajuda"}
      searchInput
      showDeleteButton={false}
      onAdd={addHanlder}
      searchInputPlaceHolder={"Pesquisar por título"}
      addButtonLabel={"Adicionar conteúdo de ajuda"}
      confirmOptions={{
        title: "Deletar conteúdo de ajuda?",
        description: "Isso vai permanentemente deletar o conteúdo de ajuda",
        confirmationButtonProps: {
          variant: "contained",
        },
      }}
    />
  );
};

export default HelpContentPage;
