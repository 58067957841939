import { GlobalAxios } from "../axios/axios-config";
import { ISelectType } from "../models/requests";
import { INewUserProfile } from "../models/user-profiles";
import { TUserProfileForm } from "../pages/AdmSystem/UserProfiles/UserProfileForm/UserProfileSchema";

const USER_PROFILES_ROUTE = process.env.REACT_APP_API + "/api/profile";

export class UserProfilesService {
  create = (item: INewUserProfile) => {
    return GlobalAxios.post(USER_PROFILES_ROUTE, item);
  };

  getItemById = (id: string) => {
    return GlobalAxios.get<TUserProfileForm>(USER_PROFILES_ROUTE + "/" + id);
  };

  updateItemById = ({ item, id }: { item: INewUserProfile; id: string }) => {
    return GlobalAxios.put(USER_PROFILES_ROUTE + "/" + id, item);
  };

  deleteItemById = (id: string) => {
    return GlobalAxios.delete(USER_PROFILES_ROUTE + "/" + id);
  };

  list = (params?: any) => {
    return GlobalAxios.get<ISelectType[]>(USER_PROFILES_ROUTE + "/list", {
      params: params,
    });
  };
}

export default UserProfilesService;
