import { Box, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Button } from "../components/UI/Button";
import { useNavigate } from "react-router-dom";
import { DevelopmentIllustration } from "../assets/illustrations";

const DevelopmentPage = () => {
  const navigate = useNavigate();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box
      height={"100%"}
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      justifyContent={"center"}
      overflow={"hidden"}
      gap={5}
    >
      <Stack width={"100%"} textAlign={"center"} gap={2}>
        <Typography variant="h4">Em desenvolvimento</Typography>
        <Typography variant="subtitle1" lineHeight={1.2}>
          Em breve novas funcionalidades estarão disponíveis aqui para você
        </Typography>
      </Stack>
      <img
        width={matches ? "100%" : 400}
        alt="Em desenvolvimento"
        src={`data:image/svg+xml;base64,${DevelopmentIllustration}`}
      />
    </Box>
  );
};

export default DevelopmentPage;
