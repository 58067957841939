import { Stack, Typography } from "@mui/material";
import { IInputFileInformationsProps } from "./models";

const InputFileInformations = ({ message }: IInputFileInformationsProps) => {
  return (
    <Stack display={"flex"} flexDirection={"row"} gap={2}>
      <Typography
        fontSize={14}
        mt={1}
        sx={(t) => ({
          color:
            t.palette.mode === "light"
              ? t.palette.grey[600]
              : t.palette.grey[400],
        })}
      >
        {message}
      </Typography>
    </Stack>
  );
};

export default InputFileInformations;
