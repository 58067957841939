import { Autocomplete, Button, Chip, Paper, TextField } from "@mui/material";
import { HTMLAttributes, memo, useCallback, useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { IAutoCompleteMultiProps } from "./models";
import { ISelectType } from "../../../../models/requests";
import { useFetch } from "../../../../hooks/useFetch";
import { useDebounce } from "../../../../hooks/useDebounce";
import { useLocation } from "react-router-dom";
import { DomainProvider } from "../../../../contexts/Domain-Context";
import AddRoundedIcon from "@mui/icons-material/AddRounded";

export const AutoCompleteMulti = memo(
  ({
    name,
    options,
    params,
    trigger,
    disable = false,
    onValueSelected,
    addButtonText,
    onAddButton,
    domain,
    actions,
    getOptionLabel = (option) => {
      return option.name;
    },
    SearchAsYouTypeParams,
    isOptionEqualToValue = (o, v) => {
      return o.id === v.id;
    },
    request,
    renderOption = (props, option) => {
      return (
        <li {...props} key={option.id}>
          {option.name}
        </li>
      );
    },
    renderSelectedOption,
    ...otherProps
  }: IAutoCompleteMultiProps) => {
    const { control, trigger: callTrigger } = useFormContext();
    const [realOptions, setOptions] = useState<ISelectType[]>(options || []);
    const { loading, sendRequest } = useFetch(request);
    const [value, setValue] = useState<string>("");
    const { pathname } = useLocation();

    const searchQuery: string = useDebounce(value, 500);

    const loadRequest = useCallback(
      async (parameters?: any) => {
        const { data, success } = await sendRequest(parameters);

        if (success) {
          setOptions(data);
        }
      },
      [sendRequest]
    );

    //Debounce get function
    useEffect(() => {
      if (SearchAsYouTypeParams) {
        if (searchQuery !== "" && searchQuery.length > 2) {
          loadRequest(SearchAsYouTypeParams(searchQuery));
        }
      }
    }, [searchQuery, SearchAsYouTypeParams, loadRequest]);

    useEffect(() => {
      if (!SearchAsYouTypeParams) {
        loadRequest(params);
      }
    }, [loadRequest, SearchAsYouTypeParams, params]);

    const paperOptions = (options: HTMLAttributes<HTMLElement> | any) => {
      const { containerProps, children } = options;
      return (
        <Paper {...containerProps}>
          {children}
          {searchQuery.length !== 0 && onAddButton && (
            <DomainProvider
              value={{ domain: domain ? domain : "" }}
              actions={actions}
              redirect={false}
            >
              <Button
                startIcon={<AddRoundedIcon />}
                size="small"
                fullWidth
                onMouseDown={(e) => {
                  e.preventDefault();
                  onAddButton(searchQuery);
                  setValue("");
                }}
              >
                {addButtonText && addButtonText(searchQuery)}
              </Button>
            </DomainProvider>
          )}
        </Paper>
      );
    };

    return (
      <Controller
        render={({ fieldState: { error }, field, formState }) => {
          const { onChange, value, ref } = field;
          return (
            <Autocomplete
              multiple
              value={value || []}
              disabled={
                disable || pathname.includes("visualizar") ? true : false
              }
              noOptionsText={"Sem opções"}
              isOptionEqualToValue={isOptionEqualToValue}
              getOptionLabel={getOptionLabel}
              PaperComponent={paperOptions}
              renderOption={renderOption}
              loading={loading}
              loadingText={"Carregando.."}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    label={
                      renderSelectedOption
                        ? renderSelectedOption(option)
                        : option.name
                    }
                    color="default"
                    size="small"
                    {...getTagProps({ index })}
                  />
                ))
              }
              options={options ? options : realOptions}
              onChange={(event: any, newValue) => {
                onChange(newValue);
                if (trigger) {
                  callTrigger(trigger);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  {...otherProps}
                  error={!!error}
                  onChange={(e) => setValue(e.target.value)}
                  inputRef={ref}
                  helperText={
                    error
                      ? error?.message
                      : SearchAsYouTypeParams
                      ? "Digite ao menos 3 caracteres para pesquisar"
                      : ""
                  }
                  fullWidth
                  sx={(t) => ({
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: pathname.includes("visualizar")
                        ? t.palette.text.primary
                        : undefined,
                    },
                  })}
                />
              )}
            />
          );
        }}
        defaultValue={[]}
        name={name}
        control={control}
      />
    );
  }
);
