import React from "react";
import { IconButton, Stack, Tooltip } from "@mui/material";

import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import ErrorIcon from "@mui/icons-material/Error";
import { IInputFilePreviewProps } from "./models";

const InputFileStatus = ({
  imagePreview,
  success,
  error,
  file,
  value,
  onUpload,
}: IInputFilePreviewProps) => {
  return (
    <>
      {value && file && success && !error && (
        <Stack
          display={"flex"}
          flexDirection={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Tooltip title={"Visualizar arquivo"}>
            <IconButton
              size="small"
              onClick={() => window.open(imagePreview, "_blank")}
            >
              <OpenInNewIcon color="success" />
            </IconButton>
          </Tooltip>
        </Stack>
      )}

      {value.fileName && !success && !error && (
        <Stack
          display={"flex"}
          flexDirection={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Tooltip title={"Visualizar arquivo"}>
            <IconButton
              size="small"
              onClick={() =>
                window.open(`${value.cdnDomain}/${value.filePath}`, "_blank")
              }
            >
              <OpenInNewIcon />
            </IconButton>
          </Tooltip>
        </Stack>
      )}

      {error && (
        <Stack
          display={"flex"}
          flexDirection={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Tooltip title={"Tentar novamente"}>
            <IconButton
              size="small"
              onClick={() => (file ? onUpload(file) : undefined)}
            >
              <ErrorIcon color="error" />
            </IconButton>
          </Tooltip>
        </Stack>
      )}
    </>
  );
};

export default InputFileStatus;
