import { Box, Stack, useMediaQuery, useTheme } from "@mui/material";
import { SearchInput } from "./SearchInput";
import { Button } from "../../Button";
import { IFilter, ITextFilterProps } from "../models";
import AutoCompleteFilter from "./AutoCompleteFilter/AutoCompleteFilter";
import DateFilter from "./DateFilter/DateFilter";

interface IFiltersModalProps {
  filters: IFilter[];
  textFilters?: ITextFilterProps[];
  applyFilters?: () => void;
  cleanFilters?: () => void;
  onClose: () => void;
}

const FiltersModal = ({
  filters,
  applyFilters,
  cleanFilters,
  textFilters,
  onClose,
}: IFiltersModalProps) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      padding={2}
      width={"100%"}
      display={"flex"}
      flexDirection={"column"}
      gap={2}
    >
      {textFilters &&
        textFilters.length !== 0 &&
        textFilters.map((item) => (
          <Stack
            sx={{
              width: "100%",
              minWidth: "200px",
            }}
            key={item.label}
          >
            <SearchInput
              label={item.label}
              onSearch={(text) => item.filter(text)}
              minLength={3}
            />
          </Stack>
        ))}
      {filters.length !== 0 &&
        filters.map((filter) => {
          if (filter.request || filter.options) {
            if (filter.shouldRender === false) return false;
            return <AutoCompleteFilter filter={filter} key={filter.label} />;
          } else {
            if (!filter.shouldRender) return false;
            return <DateFilter filter={filter} key={filter.key} />;
          }
        })}
      <Stack
        minWidth={240}
        width={"100%"}
        flexDirection={matches ? "column" : "row"}
        justifyContent={"space-between"}
        gap={2}
      >
        <Button
          variant="outlined"
          fullWidth
          onClick={() => {
            if (cleanFilters) cleanFilters();
            onClose();
          }}
        >
          Limpar filtros
        </Button>
        <Button
          variant="contained"
          fullWidth
          onClick={() => {
            if (applyFilters) applyFilters();
            onClose();
          }}
        >
          Aplicar filtros
        </Button>
      </Stack>
    </Box>
  );
};

export default FiltersModal;
