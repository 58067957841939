import React from "react";

import { Typography } from "@mui/material";
import { FC, memo } from "react";
import { Controller, useFormContext } from "react-hook-form";

import { IFileInputProps } from "./models";
import { InputFile } from "../../InputFile/InputFIle";

export const InputFileForm: FC<IFileInputProps> = memo(
  ({
    name,
    fileInputProps,
    axios,
    fileRoute,
    disable = false,
    resolution,
    information,
  }) => {
    const { control, getValues } = useFormContext();

    return (
      <Controller
        control={control}
        name={name}
        render={({ fieldState: { error }, field }) => {
          return (
            <>
              <InputFile
                axios={axios}
                fileRoute={fileRoute}
                accept={fileInputProps.accept}
                label={fileInputProps.label}
                maxSize={fileInputProps.maxFileSize}
                oldValue={getValues(name)}
                onFileSelected={fileInputProps.onFileSelected}
                size={fileInputProps.size}
                value={field.value}
                onChange={field.onChange}
                disable={disable}
                resolution={resolution}
                information={information}
                uploadFileProps={{
                  mediaTypeCode: fileInputProps.mediaTypeCode,
                  mediaTypeIdentifier: fileInputProps.mediaTypeIdentifier,
                  parentName: fileInputProps.parentName,
                  type: fileInputProps.type,
                }}
              />
              <Typography color={"error"} variant="body2">
                {error ? error?.message || "" : ""}
              </Typography>
            </>
          );
        }}
      />
    );
  }
);
