import { z } from "zod";
import { PASSWORD_VALIDATOR } from "../../../../utils/validators";

const rolesSchema = z.object(
  {
    id: z.string(),
    name: z.string(),
    code: z.string().optional().nullable(),
  },
  { invalid_type_error: "Ao menos um papel de usuário é obrigatório" }
);

const roleHiddensSchema = z.object(
  {
    id: z.string(),
    name: z.string(),
    code: z.string().optional().nullable(),
  },
  { invalid_type_error: "Ao menos um papel de usuário oculto é obrigatório" }
);

const profilesSchema = z.object(
  {
    id: z.string(),
    name: z.string(),
    code: z.string().optional().nullable(),
  },
  { invalid_type_error: "Ao menos um papel de usuário é obrigatório" }
);

const profilesHiddenSchema = z.object(
  {
    id: z.string(),
    name: z.string(),
    code: z.string().optional().nullable(),
  },
  { invalid_type_error: "Ao menos um papel de usuário oculto é obrigatório" }
);

export const userSchema = z
  .object({
    id: z.string().optional(),
    enable: z.boolean(),
    name: z.string().min(1, "O nome é obrigatório"),
    document: z.string().min(1, "O CPF é obrigatório"),
    mail: z
      .string()
      .min(1, "O e-mail é obrigatório")
      .email("Informe um e-mail válido"),
    login: z.string().min(1, "O login é obrigatório"),
    password: z.string().optional().nullable(),
    roles: z.array(rolesSchema),
    roleHiddens: z.array(roleHiddensSchema).optional(),
    profiles: z.array(profilesSchema),
    profileHiddens: z.array(profilesHiddenSchema).optional(),
  })
  .superRefine((val, ctx) => {
    if (!val.id) {
      if (val.password?.length === 0) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ["password"],
          message: "A senha é obrigatória",
        });
      }
      if (val.password) {
        if (!PASSWORD_VALIDATOR.test(val.password)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: ["password"],
            message:
              "A senha deve conter ao menos 1 letra maiúscula, 1 letra minúscula, 1 número e 1 caractere especial",
          });
        }
        if (val.password?.length < 4) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: ["password"],
            message: "A senha deve ser maior que 4 caracteres",
          });
        }
        if (val.password.length > 20) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: ["password"],
            message: "A senha deve ser menor que 20 caracteres",
          });
        }
      }
    }
  });

export type TUserForm = z.infer<typeof userSchema>;
