import { ISelectType } from "../../../models/requests";

export function not(a: ISelectType[], b: ISelectType[]) {
  return a.filter((value) => b.indexOf(value) === -1);
}

export function intersection(a: ISelectType[], b: ISelectType[]) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

export function union(a: ISelectType[], b: ISelectType[]) {
  return [...a, ...not(b, a)];
}
