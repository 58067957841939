import React, { useEffect } from "react";
import {
  IconButton,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
  Avatar as MuiAvatar,
} from "@mui/material";
import { useCallback, useState } from "react";

import { IEstablishment, IEstablishmentChangeProps } from "./models";
import EstablishmentModal from "./EstablishmentModal";
import EstablishimentBadge from "./EstablishmentBadge";
import { useParams } from "react-router-dom";
import useRouteForNewEstablishment from "../../../hooks/useRouteForNewEstablishments/useRouteForNewEstablishment";
import { Modal } from "../../UI/Modal";
import { getInitials, stringToColor } from "../../../utils";
import { useFnRequest } from "../../../hooks/useFnRequest/useFnRequest";

export const EstablishmentChange: React.FC<IEstablishmentChangeProps> = ({
  onSaveToken,
  getEstablishmentToken,
  IconButtonProps,
  establishments,
}) => {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const [url, setUrl] = useState<string>();

  const route = useRouteForNewEstablishment();

  const params = useParams();

  const eI = Number(params.eI);

  const [value, setValue] = useState<IEstablishment>();

  const { sendRequest, loading } = useFnRequest(getEstablishmentToken, [
    "create-url",
  ]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => setOpen(true);

  const actualEstablishment = establishments.find(
    (e) => e.tenantSequential === eI
  );

  const createUrl = useCallback(
    async (value: IEstablishment) => {
      if (value.id === actualEstablishment?.id) return;

      const { data, success } = await sendRequest(value.id);

      if (success) {
        onSaveToken(
          data.token,
          data.refreshToken,
          +data.establishmentTenantSequential,
          value.id
        );
        const url = window.location.origin;

        setUrl(url + "/e/" + data.establishmentTenantSequential + route);
      }
    },
    [sendRequest, route, onSaveToken, actualEstablishment]
  );

  useEffect(() => {
    if (open) {
      setValue(establishments.find((e: any) => e.tenantSequential === eI));
    }
  }, [establishments, eI, open]);

  const handleClick = useCallback(() => {
    if (!loading && url) {
      window.open(url, "_blank");
      handleClose();
    }
  }, [loading, url]);

  const handleChange = (newValue: IEstablishment) => {
    setValue(newValue);
    createUrl(newValue);
  };

  return (
    <>
      {establishments.length === 1 && !matches && (
        <Typography color={"text.primary"}>
          {actualEstablishment?.name}
        </Typography>
      )}
      {establishments.length === 1 && matches && (
        <Tooltip title={actualEstablishment?.name}>
          <MuiAvatar
            sx={(theme) => ({
              marginRight: 2,
              bgcolor: stringToColor(actualEstablishment?.name || ""),
              width: 30,
              height: 30,
              color: theme.palette.getContrastText(
                stringToColor(actualEstablishment?.name || "")
              ),
            })}
          >
            {getInitials(actualEstablishment?.name || "")}
          </MuiAvatar>
        </Tooltip>
      )}
      {establishments.length >= 2 && (
        <>
          {!matches && (
            <Typography color={"text.primary"}>
              {actualEstablishment?.name}
            </Typography>
          )}
          <Tooltip title={"Trocar de estabelecimento"} placement="right">
            <IconButton {...IconButtonProps} onClick={handleOpen}>
              <EstablishimentBadge establishment={actualEstablishment} />
            </IconButton>
          </Tooltip>
          {value && (
            <Modal open={open} onClose={handleClose}>
              <EstablishmentModal
                establishments={establishments}
                open={open}
                actualEstablishment={actualEstablishment}
                onClose={handleClose}
                onClick={handleClick}
                value={value}
                loading={false}
                onChange={handleChange}
              />
            </Modal>
          )}
        </>
      )}
    </>
  );
};
