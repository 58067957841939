import React from "react";
import { TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { ChangeEvent, memo, useEffect, useState } from "react";

import { ISearchInput } from "./models";
import { useDebounce } from "../../../../hooks/useDebounce";

export const SearchInput: React.FC<ISearchInput> = memo(
  ({ onSearch, placeholder, focus = true, debounce = 500 }) => {
    const [value, setValue] = useState<string>();

    const searchQuery = useDebounce(value, debounce);
    useEffect(() => {
      onSearch(searchQuery);
    }, [searchQuery, onSearch]);

    const onChangeHandler = (
      event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
      setValue(event.target.value);
    };

    return (
      <TextField
        fullWidth
        onChange={onChangeHandler}
        id="input-with-icon-textfield"
        label="Pesquisar"
        placeholder={placeholder}
        size="small"
        autoFocus={focus}
        InputProps={{
          endAdornment: <SearchIcon />,
        }}
        variant="outlined"
      />
    );
  }
);
