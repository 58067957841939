import { GlobalAxios } from "../axios/axios-config";
import { ISelectType } from "../models/requests";

export class GenericService {
  route_path: string;

  constructor(rp: string) {
    this.route_path = rp;
  }

  list = (params: string) => {
    return GlobalAxios.get<ISelectType[]>(this.route_path + `/${params}`);
  };
}

export default GenericService;
