import {
  Card,
  CardHeader,
  Checkbox,
  CircularProgress,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { TListSelect } from "./models";
import { useLocation } from "react-router-dom";

const ListSelect = ({
  children,
  items,
  title,
  checked,
  numberOfChecked,
  handleToggle,
  handleToggleAll,
  loading,
}: TListSelect) => {
  const { pathname } = useLocation();
  const viewMode = pathname.includes("visualizar");

  return (
    <Card sx={{ marginTop: 0.5 }}>
      <Grid container sx={{ p: 2 }}>
        {children}
      </Grid>
      <CardHeader
        sx={{ px: 2, py: 1 }}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={
              numberOfChecked(items) === items.length && items.length !== 0
            }
            indeterminate={
              numberOfChecked(items) !== items.length &&
              numberOfChecked(items) !== 0
            }
            disabled={items.length === 0 || viewMode}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} selecionado(s)`}
      />
      <Divider />
      <List
        sx={(theme) => ({
          width: 340,
          height: 230,
          overflow: "auto",
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor:
              theme.palette.mode === "dark"
                ? theme.palette.grey[900]
                : theme.palette.grey[200],
          },
        })}
        dense
        component="div"
        role="list"
      >
        {loading && (
          <Grid
            container
            item
            justifyContent="center"
            alignItems="center"
            sx={{ marginTop: "25%" }}
          >
            <CircularProgress />
          </Grid>
        )}
        {!loading && items.length === 0 && (
          <Grid
            container
            item
            justifyContent="center"
            alignItems="center"
            sx={{ marginTop: "25%" }}
          >
            <Typography variant="body1">Sem informações</Typography>
          </Grid>
        )}
        {!loading &&
          items.map((value) => {
            return (
              <ListItem
                key={value.id}
                role="listitem"
                onClick={!viewMode ? handleToggle(value) : undefined}
                sx={{ paddingBlock: 0, paddingInline: "16px" }}
              >
                <ListItemIcon>
                  <Checkbox
                    checked={checked.indexOf(value) !== -1}
                    tabIndex={-1}
                    disableRipple
                    disabled={viewMode}
                  />
                </ListItemIcon>
                {value.description && (
                  <ListItemText
                    id={value.id}
                    primary={`${value.description}`}
                  />
                )}
              </ListItem>
            );
          })}
      </List>
    </Card>
  );
};

export default ListSelect;
