import { Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";

import FolderOff from "@mui/icons-material/FolderOff";
import DoDisturbAltRoundedIcon from "@mui/icons-material/DoDisturbAltRounded";
import { useRole } from "../../../hooks/useRole";

export const NoRowsMessage = ({ inCenter = true }: { inCenter?: boolean }) => {
  const [message, setMessage] = useState<string>(
    "Nenhuma informação encontrada"
  );
  const readPermission = useRole(["Read"]);

  useEffect(() => {
    if (!readPermission) {
      setMessage("Você não possui permissão para visualizar as informações");
    } else {
      setMessage("Nenhuma informação encontrada");
    }
  }, [readPermission]);

  return (
    <Stack
      alignItems={"center"}
      justifyContent={inCenter ? "center" : "start"}
      height={"100%"}
      mt={inCenter ? 0 : 5}
    >
      {!readPermission && (
        <DoDisturbAltRoundedIcon
          sx={(theme) => ({
            fontSize: 40,
            mb: 1,
            color:
              theme.palette.mode === "light"
                ? theme.palette.grey[600]
                : theme.palette.grey[400],
          })}
        />
      )}
      {readPermission && (
        <FolderOff
          sx={(theme) => ({
            fontSize: 40,
            mb: 1,
            color:
              theme.palette.mode === "light"
                ? theme.palette.grey[600]
                : theme.palette.grey[400],
          })}
        />
      )}
      <Typography>{message}</Typography>
    </Stack>
  );
};
