import { RouteObject } from "react-router-dom";
import { DomainProvider } from "../contexts/Domain-Context";
import UsersPage from "../pages/AdmSystem/Users/UsersPage";
import User from "../pages/AdmSystem/Users/UserForm/User";
import RolesPage from "../pages/AdmSystem/Roles/RolesPage";
import Role from "../pages/AdmSystem/Roles/RoleForm/Role";
import UserProfilesPage from "../pages/AdmSystem/UserProfiles/UserProfilesPage";
import UserProfile from "../pages/AdmSystem/UserProfiles/UserProfileForm/UserProfile";

const AccessRoutes: RouteObject[] = [
  {
    path: "usuarios",
    element: (
      <DomainProvider value={{ domain: "user" }}>
        <UsersPage />
      </DomainProvider>
    ),
  },
  {
    path: "usuarios/novo",
    element: (
      <DomainProvider value={{ domain: "user" }} actions={["Create"]}>
        <User />
      </DomainProvider>
    ),
  },
  {
    path: "usuarios/:id/editar",
    element: (
      <DomainProvider value={{ domain: "user" }} actions={["Update"]}>
        <User />
      </DomainProvider>
    ),
  },
  {
    path: "usuarios/:id/visualizar",
    element: (
      <DomainProvider value={{ domain: "user" }}>
        <User />
      </DomainProvider>
    ),
  },
  {
    path: "papeis",
    element: (
      <DomainProvider value={{ domain: "role" }}>
        <RolesPage />
      </DomainProvider>
    ),
  },
  {
    path: "papeis/novo",
    element: (
      <DomainProvider value={{ domain: "role" }} actions={["Create"]}>
        <Role />
      </DomainProvider>
    ),
  },
  {
    path: "papeis/:id/editar",
    element: (
      <DomainProvider value={{ domain: "role" }} actions={["Update"]}>
        <Role />
      </DomainProvider>
    ),
  },
  {
    path: "papeis/:id/visualizar",
    element: (
      <DomainProvider value={{ domain: "role" }}>
        <Role />
      </DomainProvider>
    ),
  },
  {
    path: "perfis",
    element: (
      <DomainProvider value={{ domain: "profile" }}>
        <UserProfilesPage />
      </DomainProvider>
    ),
  },
  {
    path: "perfis/novo",
    element: (
      <DomainProvider value={{ domain: "profile" }} actions={["Create"]}>
        <UserProfile />
      </DomainProvider>
    ),
  },
  {
    path: "perfis/:id/editar",
    element: (
      <DomainProvider value={{ domain: "profile" }} actions={["Update"]}>
        <UserProfile />
      </DomainProvider>
    ),
  },
  {
    path: "perfis/:id/visualizar",
    element: (
      <DomainProvider value={{ domain: "profile" }}>
        <UserProfile />
      </DomainProvider>
    ),
  },
];

export default AccessRoutes;
