import { useMutation, useQueryClient } from "react-query";
import { Trequest } from "../useRequest/models";
import { useNotificationContext } from "../../contexts/Notification-Context";
import { useAuthContext } from "../../contexts/Auth-Context";
import { Treturn } from "../useFetch";
import { useCallback } from "react";
import { AxiosError } from "axios";

export const useFnRequest = <T, X>(
  request: Trequest<T, X>,
  queries?: string[]
) => {
  const queryClient = useQueryClient();
  const notificationContext = useNotificationContext();
  const authContext = useAuthContext();
  const { setMessage } = notificationContext;
  const { onLogoutUser } = authContext;

  const { mutateAsync, isLoading, error, isSuccess } = useMutation(
    (requestBody: T) => {
      return request(requestBody);
    }
  );

  const sendRequest: (requestBody: T) => Promise<Treturn<X>> = useCallback(
    async (requestBody: T) => {
      try {
        const { data, status } = await mutateAsync(requestBody);
        if (status === 204) {
          return {
            success: false,
            data: null,
          };
        }

        if (queries) {
          queryClient.invalidateQueries(queries);
        }

        return {
          success: true,
          data: data,
        };
      } catch (err: any) {
        if (err?.response) {
          if (err?.response?.status === 401) {
            onLogoutUser();
            return {
              success: false,
              data: null,
            };
          }
          if (err.response.data.message) {
            if (Array.isArray(err.response.data.message)) {
              setMessage({
                message: err.response.data.message.join(" | "),
                type: "error",
              });
            } else {
              setMessage({
                message: err.response.data.message,
                type: "error",
              });
            }

            return {
              success: false,
              data: null,
            };
          }
          if (err.response?.data.errors) {
            let errorMessage = "";

            Object.keys(err.response?.data.errors).forEach((key) => {
              errorMessage += err.response?.data.errors[key].join(" | ") + " ";
            });

            setMessage({
              message: errorMessage,
              type: "error",
            });

            return {
              success: false,
              data: null,
            };
          }
          if (err.response?.data.detail) {
            setMessage({
              message: err.response?.data.detail || err.message,
              type: "error",
            });
            return {
              success: false,
              data: null,
            };
          }

          setMessage({
            message: err.message,
            type: "error",
          });
        } else {
          if (err.code === "ERR_CANCELED") {
            setMessage({
              message: "Arquivo não enviado",
              type: "error",
            });
          } else {
            setMessage({
              message: err.message,
              type: "error",
            });
          }
        }
        return {
          success: false,
          data: null,
        };
      }
    },

    [mutateAsync, onLogoutUser, queries, queryClient, setMessage]
  );

  return {
    sendRequest,
    loading: isLoading,
    error: error as AxiosError,
    isSuccess,
  };
};
