import { Autocomplete, Stack, TextField } from "@mui/material";
import { IFilter } from "../../models";
import { useEffect, useState } from "react";
import { ISelectType } from "../../../../../contexts/Config-Context";
import ClearIcon from "@mui/icons-material/Clear";
import { useRequest } from "../../../../../hooks/useRequest/useRequets";
import { useLocation } from "react-router-dom";
import { useDebounce } from "../../../../../hooks/useDebounce";

const AutoCompleteFilter = ({ filter }: { filter: IFilter }) => {
  const { pathname } = useLocation();

  const localData = localStorage.getItem(`${filter.key}.${pathname}`);

  const [value, setValue] = useState<ISelectType | null>(
    localData ? JSON.parse(localData) : null
  );

  const [searchText, setSearchText] = useState("");

  const searchValue = useDebounce(searchText, 500);

  const hasSearch = filter.search && searchValue.length >= 3 ? true : false;

  const hasNoSearch =
    (!filter.search && !!filter.request && !!filter.requestBody) ||
    (!filter.search && typeof filter.requestBody === "string");

  const { data, isLoading } = useRequest({
    queryKey: [filter.key],
    requestBody: filter.search
      ? { [filter.requestBody]: searchValue }
      : filter.requestBody,
    request: filter.request,
    permission: hasSearch || hasNoSearch,
  });

  const updateFilter = (currentValue: ISelectType) => {
    const returnType = filter.return?.toString() ?? "id";

    localStorage.setItem(
      `${filter.key}.${pathname}`,
      JSON.stringify({ ...currentValue, description: returnType })
    );
  };

  const removeFilter = () => {
    localStorage.removeItem(`${filter.key}.${pathname}`);
  };

  useEffect(() => {
    if (filter.getSelectedValue) {
      filter.getSelectedValue(value ?? null);
    }
  }, [value, filter]);

  return (
    <Stack
      sx={{
        width: "100%",
        minWidth: "240px",
      }}
    >
      <Autocomplete
        options={
          filter.request && data ? data : filter.options ? filter.options : []
        }
        loading={isLoading}
        value={value}
        noOptionsText={filter.noOptionsText || "Sem opções"}
        clearIcon={<ClearIcon fontSize="small" />}
        isOptionEqualToValue={(option, value) => {
          return option.id === value.id;
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={filter.label}
            onChange={(e) => {
              filter.search && setSearchText(e.target.value);
            }}
            helperText={filter.search && "Informe ao menos 3 caracteres"}
          />
        )}
        onInputChange={(event, newInputValue, reason) => {
          if (filter.search) {
            if (reason === "reset") {
              setSearchText("");
              return;
            } else {
              setSearchText(newInputValue);
            }
          }
        }}
        getOptionLabel={(option) => option.name}
        renderOption={(props, option) => {
          return (
            <li {...props} key={option.id}>
              {option.name}
            </li>
          );
        }}
        onChange={(event, value) => {
          if (value) {
            setValue(value);
            if (!pathname) return;
            updateFilter(value);
          } else {
            setValue(null);
            removeFilter();
          }
        }}
        size="small"
        fullWidth
        sx={{ width: "100%" }}
      />
    </Stack>
  );
};

export default AutoCompleteFilter;
