import { Box, Stack } from "@mui/material";
import SideMenuItem from "./SideMenuItem";
import MenusService from "../../../services/menus.service";
import { useRequest } from "../../../hooks/useRequest/useRequets";

const menusService = new MenusService();

const MenuList = ({ onClose }: { onClose?: () => void }) => {
  const { data: menus } = useRequest({
    queryKey: ["menu"],
    request: menusService.list,
    requestBody: null,
  });

  return (
    <Stack sx={{ height: "calc(100vh - 10rem)", overflowY: "auto" }} mt={5}>
      <Stack component="nav" spacing={0.5} sx={{ px: 2 }}>
        {menus &&
          menus.map((item) => (
            <SideMenuItem
              key={item.name}
              item={item}
              onClose={onClose}
              menus={menus}
            />
          ))}
      </Stack>
      <Box sx={{ flexGrow: 1 }} />
    </Stack>
  );
};

export default MenuList;
